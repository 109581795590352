import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#454D5F',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 400,
    padding: 10,
    marginLeft: '9px !important',
    marginRight: '0 !important',
    borderRadius: '0px 3px 3px 0px',
  },
}));
