import { observable, action, makeAutoObservable, autorun } from 'mobx';
import NotificationStore, { NotificationProps } from './notificationStore';
import api from './request';
import apiAuth from './requestWithAuth';

export const RRG_TIER = 3;
export const SIGNAL_TIER = 2;
export const BUSINESS_TIER = 1;

export interface authStoreProps {
  userData: UserData;
  isAuthenticated: boolean;
  loading: boolean;
  paymentChecked: boolean;
  forgotSent: boolean;
  login: (data: any) => any;
  signup: (data: any) => any;
  forgot: (data: any) => any;
  reset: (data: any, token: any) => any;
  logout: () => any;
  signUpData: SignUpData;
  openLogin: boolean;
  setOpenLogin: (openAction: any) => any;
  selectedPeriod: number;
  setPeriod: (selectedPeriod: number) => any;
  selectedTier: number;
  setTier: (selectedTier: number) => any;
  confirmPay: () => any;
  openPaymentPopup: boolean;
  setOpenPayment: () => any;
  loadUserTier: () => any;
  userTierData: TierData[];
  userTierCheck: (tierType: number) => any;
  isShowTierUpdate: boolean;
  setShowTierUpdate: (isShowTierUpdate: any) => any;
  applyDiscount: (discountData: any) => any;
  showDiscountBlock: boolean;
  discountPrice: number;
  discountCode: string;
  loadUserInfo: () => any;
  userInfoData: UserInfoData;
  applyChangeUserName: (userData: any) => any;
}

export interface SignUpData {
  email: string;
  signedUp: boolean;
}

export interface UserData {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  token?: string;
}

export interface TierData {
  user_id: number;
  type: number;
  month: number;
  start: string;
  end: string;
}

export interface UserInfoData {
  id: number;
  name: string;
  email: string;
  phone: string;
  email_verified_at: string;
  status: number;
  role: number;
  created_at: string;
  updated_at: string;
  payment_history: PaymentHistoryData[];
}

export interface PaymentHistoryData {
  id: number;
  user_id: number;
  type: number;
  month: string;
  code: string;
  discount: number;
  price: number;
  start: string;
  end: string;
  status: number;
  created_at: string;
  updated_at: string;
  is_deleted: number;
}

class AuthStore implements authStoreProps {
  private apiLoginPath: string = 'api/login';
  private apiSignUpPath: string = 'api/signup';
  private apiForgotPath: string = 'api/reset';
  private apiResetPath: string = 'api/change';
  private apiResetPWPath: string = 'api/reset-change';
  private apiConfirmPay: string = 'api/payment';
  private apiUserInfo: string = 'api/user';
  private apiDiscountPath: string = 'api/discount';
  private messageStore: NotificationProps = NotificationStore;

  @observable
  userData: UserData = {};

  @observable
  isAuthenticated = false;

  @observable
  openPaymentPopup = false;

  @observable
  showDiscountBlock = false;

  @observable
  discountPrice = 0;

  @observable
  forgotSent = false;

  @observable
  loading: boolean = false;

  @observable
  paymentChecked: boolean = false;
  
  @observable
  openLogin: boolean = false;

  @observable
  signUpData: SignUpData = { email: '', signedUp: false };

  @observable
  selectedPeriod: number = 0;

  @observable
  selectedTier: number = 0;

  @observable
  userTier: number = 0;

  @observable
  isShowTierUpdate: boolean = false;

  @observable
  discountCode: string = '';

  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      this.userData = JSON.parse(localStorage.getItem('userData')!) || {};
      this.isAuthenticated = !!localStorage.getItem('access_token');
    });
  }

  @observable
  userInfoData!: UserInfoData;

  @action
  public userTierCheck = (tierType: number) => {
    let returnData = false;
    this.userTierData.forEach((tier) => {
      if (tier.type == tierType) {
        returnData = true;
      }
    });
    return returnData;
  };

  @observable
  userTierData: TierData[] = [];

  @action
  public setPeriod = (selectedPeriod: number) => {
    this.selectedPeriod = selectedPeriod;
  };

  @action
  public setTier = (selectedTier: number) => {
    this.selectedTier = selectedTier;
    this.discountCode = '';
    this.discountPrice = 0;
  };

  @action
  public setOpenPayment = () => {
    this.openPaymentPopup = false;
  };

  @action
  public setShowTierUpdate = (isShowTierUpdate: any) => {
    this.isShowTierUpdate = isShowTierUpdate;
  };

  @action
  loadUserInfo = async () => {
    this.loading = true;
    await apiAuth
      .get(this.apiUserInfo)
      .then((resp) => resp.data)
      .then(this.fetchUserDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchUserDataSuccess = (dataR: any) => {
    this.loading = false;
    this.userInfoData = dataR;
  };

  @action
  applyChangeUserName = async (userData: any) => {
    this.loading = true;
    const queryString: string = `${this.apiUserInfo}`;
    if (userData.name.length < 1) userData.name = this.userData.name;
    if (userData.phone.length < 1) userData.phone = this.userData.phone;
    await apiAuth
      .post(queryString, userData)
      .then(this.fetchChangeUserNameSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchChangeUserNameSuccess = (dataR: any) => {
    this.loading = false;
    this.userData.name = dataR.data.data.name;
    this.userData.phone = dataR.data.data.phone;
    localStorage.setItem('userData', JSON.stringify(this.userData));
  };

  @action
  applyDiscount = async (discountData: any) => {
    this.discountCode = discountData.code;
    this.loading = true;
    this.showDiscountBlock = false;
    this.discountPrice = 0;
    const queryString: string = `${this.apiDiscountPath}`;
    await apiAuth
      .post(queryString, discountData)
      .then(this.fetchDiscountDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDiscountDataSuccess = (dataR: any) => {
    this.loading = false;
    if (dataR.data && dataR.data.status == true) {
      this.showDiscountBlock = true;
      this.discountPrice = dataR.data.price;
      this.messageStore.clearMessage();
    } else {
      this.messageStore.showErrorMessage(
        'Rất tiếc mã giảm giá bạn nhập không hợp lệ !',
      );
      this.discountCode = '';
    }
  };

  @action
  login = async (data: any) => {
    this.loading = true;
    const queryString: string = `${this.apiLoginPath}`;
    await api
      .post(queryString, data)
      .then((resp) => resp.data)
      .then(this.fetchLoginDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  signup = async (data: any) => {
    this.loading = true;
    const queryString: string = `${this.apiSignUpPath}`;
    await api
      .post(queryString, data)
      .then((resp) => resp.data)
      .then(this.fetchSignUpDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  confirmPay = async () => {
    this.openPaymentPopup = false;
    this.loading = true;
    const queryString: string = `${this.apiConfirmPay}`;
    let payData = {
      type: this.selectedTier + 1,
      month: this.selectedPeriod,
      code: this.discountCode,
    };
    await apiAuth
      .post(queryString, payData)
      .then((resp) => resp.data)
      .then(() => {
        this.loading = false;
        this.openPaymentPopup = true;
        this.discountPrice = 0;
        this.discountCode = '';
      })
      .catch(this.fetchDataError);
  };

  @action
  loadUserTier = async () => {
    if (this.userData.email && this.userData.email.length > 0 && this.isAuthenticated) {
      const queryString: string = `${this.apiConfirmPay}`;
      await apiAuth
        .get(queryString)
        .then((resp) => {(this.userTierData = resp.data);
          this.paymentChecked = true})
        .catch(this.fetchDataError);
    }
  };

  @action
  forgot = async (data: any) => {
    this.loading = true;
    const queryString: string = `${this.apiForgotPath}`;
    await api
      .post(queryString, data)
      .then((resp) => resp.data)
      .then(() => {
        this.loading = false;
        this.forgotSent = true;
      })
      .catch(this.fetchDataError);
  };

  @action
  reset = async (data: any, token: string) => {
    let queryString: string = `${this.apiResetPWPath}`;
    if (token === null) {
      let savedToken = window.localStorage.getItem('access_token');
      if (savedToken !== null) token = savedToken;
      queryString = `${this.apiResetPath}`;
    }

    this.loading = true;

    const _pass = { token: token, password: data.password };
    await api
      .post(queryString, _pass)
      .then((resp) => resp.data)
      .then(() => {
        this.loading = false;
        this.forgotSent = true;
      })
      .catch(this.fetchDataError);
  };

  @action
  private fetchLoginDataSuccess = (dataR: any) => {
    this.loading = false;
    localStorage.setItem('access_token', dataR.token);
    this.userData = dataR;
    localStorage.setItem('userData', JSON.stringify(this.userData));
    this.isAuthenticated = true;
  };

  @action
  private fetchSignUpDataSuccess = (dataR: any) => {
    this.loading = false;
    this.signUpData.email = dataR.user.email;
    this.signUpData.signedUp = true;
  };

  @action logout = () => {
    //console.log('logout');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userData');
    this.isAuthenticated = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    //console.log(error.response.data.errors);
    let message = error.response.data.message;
    this.loading = false;
    if (!error.response.data.success) {
      this.logout();
    }
    if (typeof error.response.data.errors !== 'string') {
      for (var key in error.response.data.errors) {
        message = error.response.data.errors[key];
        break;
      }
    }

    this.messageStore.showErrorMessage('Lỗi : ' + message);
  };

  @action
  setOpenLogin = (openAction: any) => {
    this.openLogin = openAction;
  };
}

export default AuthStore;
