// import { Navigate, useLocation } from "react-router-dom";
// import { observer } from 'mobx-react';

const AuthenticatedRoute = ({ children }: { children: JSX.Element }) => {
  // let location = useLocation();
  // const { authStore } = useStores();
  // const { isAuthenticated } = authStore;
  // if (!isAuthenticated) {
  //     return <Navigate to="/login" state={{ from: location }} />;
  // }
  return children;
};

export default AuthenticatedRoute;
