import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export const DatePeroidTypes = ['30D', '60D', '90D', '180D'];

export interface ReportingAnalysisProps {
  data: {
    1: ReportData[];
    2: ReportData[];
    3: ReportData[];
    4: ReportData[];
  };
  topData: ReportTopData[];
  loading: boolean;
  datePeroid: string;
  selectedCode: SelectedCode;
  chartSelectedType: number;
  setDatePeroid: (timePeroid: string) => void;
  setReportSelectedCode: (codeData: any) => void;
  setChartType: (type: number) => void;
  paginations: {
    1: PaginationData;
    2: PaginationData;
    3: PaginationData;
    4: PaginationData;
  };
  pageOnchange: (tabIndex: number, pagination: PaginationData) => void;
  activeTab: number;
  setActiveTab: (type: number) => void;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
}

export interface PaginationData {
  page: number;
  count: number;
}

export interface SelectedCode {
  title: string;
  content: string;
}

export interface ReportData {
  id: number;
  type: number;
  code: string;
  title: string;
  content: string;
  source: string;
  recommendations: number;
  target: number;
  profit: number;
  growth: number;
  date: string;
  file: string;
  upside: number;
}

export interface ReportTopData {
  code: string;
  value: number;
  tooltip: ReportTopTooltipData[];
}

export interface ReportTopTooltipData {
  code: string;
  source: string;
  date: string;
}

class ReportingAnalysisStore implements ReportingAnalysisProps {
  private apiPath: string = 'api/report';
  private apiTopPath: string = 'api/report/top';

  @observable
  data: {
    1: ReportData[];
    2: ReportData[];
    3: ReportData[];
    4: ReportData[];
  } = {
    1: [],
    2: [],
    3: [],
    4: [],
  };

  @observable
  topData: Array<ReportTopData> = [];

  @observable
  loading: boolean = false;

  @observable
  datePeroid: string = '30D';

  @observable
  chartSelectedType: number = 1;

  @observable
  selectedCode: SelectedCode = {
    title: 'Tóm tắt báo cáo',
    content: '',
  };

  @observable
  paginations: {
    1: PaginationData;
    2: PaginationData;
    3: PaginationData;
    4: PaginationData;
  } = {
    1: { page: 1, count: 10 },
    2: { page: 1, count: 10 },
    3: { page: 1, count: 10 },
    4: { page: 1, count: 10 },
  };

  @observable
  activeTab: number = 0;

  @observable
  searchValue: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  pageOnchange = (tabIndex: number, pagination: PaginationData) =>
    ((this.paginations as any)[tabIndex] = pagination);

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadContentData = async (id: number) => {
    this.loading = true;
    const queryString: string = `${this.apiPath}/${id}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchContentDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadTopData = async () => {
    //this.loading = true;
    const queryString: string = `${this.apiTopPath}?days=${this.datePeroid}&type=${this.chartSelectedType}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchTopDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    for (let i = 1; i <= 4; i++) {
      this.pageOnchange(i, {
        page: 1,
        count: Math.round((this.data as any)[i].length / 10),
      });
    }
    this.loading = false;
  };

  @action
  private fetchTopDataSuccess = (dataR: any) => {
    this.topData = dataR;
    //this.loading = false;
  };

  @action
  private fetchContentDataSuccess = (dataR: any) => {
    this.selectedCode.content = dataR[0].content;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public setDatePeroid = (datePeroid: string) => {
    this.datePeroid = datePeroid;
    this.loadTopData();
  };

  @action
  public setReportSelectedCode = (codeData: any) => {
    this.selectedCode.title =
      'Tóm tắt báo cáo ' +
      (codeData.type === 1 ? 'cổ phiếu ' : '') +
      codeData.code;
    this.loadContentData(codeData.id);
  };

  @action
  public setChartType = (type: number) => {
    this.chartSelectedType = type;
    this.loadTopData();
  };

  @action
  public setActiveTab = (tabIndex: number) => {
    this.activeTab = tabIndex;
  };

  @action
  public setSearchValue = (searchValue: string) => {
    this.searchValue = searchValue;
  };
}

export default new ReportingAnalysisStore();
