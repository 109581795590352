import { observable, action, makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { StatusDateFormat } from '../utils/DateFormat';
import api from './request';
import WSClient, { WSClientProps } from './wsClient';

export const TimePeroidTypes = ['1m', '3m', '6m', '1y'];

export interface Floor {
  id: number;
  name: string;
}

export interface Filters {
  floors: Array<Floor>;
  min_adClose?: number;
  max_adClose?: number;
  min_MA20?: number;
  max_MA20?: number;
}

export const FloorTypes: Array<Floor> = [
  { id: 1, name: 'hnx' },
  { id: 2, name: 'hose' },
  { id: 3, name: 'upcom' },
];

export interface PTTBChartProps {
  data: PTTBChartDataNode;
  dataTable: {
    list: Array<PTTBData>;
    total: number;
  };
  filters: Filters;
  updateFilters: (filters: Filters) => void;
  updateDataTable: (dataTable: {
    list: Array<PTTBData>;
    total: number;
  }) => void;
  loadData: (isFirstLoad?: boolean, isLoading?: boolean) => void;
  loadDataTable: () => void;
  loadDataEnNote: () => void;
  startWebSocket: () => void;
  endWebSocket: () => void;
  setTimePeroid: (timePeroid: string) => void;
  setOpenZoom: (open: boolean) => void;
  timePeroid: string;
  loading: boolean;
  loadingTable: boolean;
  openZoom: boolean;
  charts: any;
  setCharts: (charts: any) => void;
  statusTime?: string;
  statusTimeTable?: string;
}

export interface PTTBData {
  code: string;
  adClose: number;
  pctChange: number;
  ma20: number;
  ma50: number;
  ma200: number;
  volMa20: number;
  nmVolume: number;
  ceilingPrice: number;
  floorPrice: number;
  statusAdClose?: 0 | 1 | 2;
  statusPctChange?: 0 | 1 | 2;
  statusVolMa20?: 0 | 1 | 2;
  statusNmVolume?: 0 | 1 | 2;
}

export interface PTTBChartData {
  x: string;
  y?: number;
  value: number;
  total: number;
}

export interface PTTBChartDataNode {
  minX: number | string;
  min1Y: number;
  max1Y: number;
  data: {
    ma20: Array<PTTBChartData>;
    ma50: Array<PTTBChartData>;
    ma200: Array<PTTBChartData>;
  };
}

class PTTBChartStore implements PTTBChartProps {
  private apiPath: string = 'api/stock/avg';
  private echoChannel: string = 'MAEvent';
  private wsClient: WSClientProps = WSClient;

  private dataList: any = [];

  @observable
  dataTable: {
    list: Array<PTTBData>;
    total: number;
  } = {
    list: [],
    total: 0,
  };

  @observable
  charts: any = { value1: true, value2: true, value3: true };

  @observable
  data: PTTBChartDataNode = {
    minX: 0,
    min1Y: 0,
    max1Y: 0,
    data: {
      ma20: [],
      ma50: [],
      ma200: [],
    },
  };

  @observable
  filters: Filters = {
    floors: FloorTypes,
    min_adClose: 10,
    max_adClose: 500,
    min_MA20: 50,
    max_MA20: 200000,
  };

  @observable
  openZoom: boolean = false;

  @observable
  loading: boolean = false;

  @observable
  loadingTable: boolean = false;

  @observable
  timePeroid: string = '6m';

  @observable
  statusTime?: string = '';

  @observable
  statusTimeTable?: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  updateDataTable = (dataTable: { list: Array<PTTBData>; total: number }) => {
    this.dataTable = dataTable;
  };

  @action
  setCharts = (charts: any) => {
    this.charts = charts;
    this.loadDataTable();
  };

  @action
  public updateFilters = async (filters: Filters) => {
    this.filters = filters;
    this.openZoom && (await this.loadDataTable());
    await this.loadData();
    this.loadDataEnNote();
  };

  @action
  public setOpenZoom = async (open: boolean) => {
    this.openZoom = open;
    if (open) {
      await this.loadDataTable();
      this.loadDataEnNote();
    }
  };

  @action
  public setTimePeroid = async (timePeroid: string) => {
    this.timePeroid = timePeroid;
    await this.loadDataTable();
    await this.loadData();
    this.loadDataEnNote();
  };

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.dataList = [];
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  private fetchDataSocketSuccess = (data: any) => {
    if (!data.end) {
      this.dataList = [...this.dataList, ...data.data];
    }
    if (data.end) {
      const dataTmp = [...this.dataList, ...data.data];
      const dataFiltes = dataTmp.filter((t: any) => this.isDependsFilters(t));
      const data2 = dataFiltes.filter((t: any) => this.isDependsONFilters(t));
      if (dataFiltes.length > 0) {
        const endNode1: any = {
          date: dataFiltes[0].date,
          total: dataFiltes.length,
        };
        const endNode2: any = {
          date: dataFiltes[0].date,
          total: dataFiltes.length,
        };
        const endNode3: any = {
          date: dataFiltes[0].date,
          total: dataFiltes.length,
        };
        let value1 = 0;
        let value2 = 0;
        let value3 = 0;
        for (let index = 0; index < dataFiltes.length; index++) {
          if (dataFiltes[index].over20) {
            value1 = value1 + 1;
          }
          if (dataFiltes[index].over50) {
            value2 = value2 + 1;
          }
          if (dataFiltes[index].over200) {
            value3 = value3 + 1;
          }
        }
        endNode1.value = value1.toString();
        endNode2.value = value2.toString();
        endNode3.value = value3.toString();
        this.fetchDataEndNoteSuccess({
          ma20: [endNode1],
          ma50: [endNode2],
          ma200: [endNode3],
        });
      }
      if (this.dataTable.list.length > 0) {
        this.dataTable = {
          total: dataFiltes.length,
          list: data2.map((t: any) => {
            const itemOld = this.dataTable.list.find(
              (k) => t.code.toLowerCase() === k.code.toLowerCase(),
            );
            return {
              code: t.code,
              adClose: parseFloat(t.adClose),
              pctChange: parseFloat(t.pctChange),
              ma20: t.ma20,
              ma50: t.ma50,
              ma200: t.ma200,
              volMa20: t.volMa20,
              nmVolume: parseFloat(t.nmVolume),
              ceilingPrice: itemOld ? itemOld.ceilingPrice : 0,
              floorPrice: itemOld ? itemOld.floorPrice : 0,
            };
          }),
        };
        const time = moment().format(StatusDateFormat);
        this.statusTimeTable = time.toString();
      }
      this.dataList = [];
    }
  };

  private isDependsFilters = (t: any): boolean => {
    const { min_adClose, max_adClose, min_MA20, max_MA20, floors } =
      this.filters;
    if (
      parseFloat(t.adClose) >= min_adClose! &&
      parseFloat(t.adClose) <= max_adClose! &&
      t.volMa20 >= min_MA20! * 1000 &&
      t.volMa20 <= max_MA20! * 1000
    ) {
      if (floors.length === 3) {
        if (
          t.floor.toLowerCase() === floors[0].name.toLowerCase() ||
          t.floor.toLowerCase() === floors[1].name.toLowerCase() ||
          t.floor.toLowerCase() === floors[2].name.toLowerCase()
        )
          return true;
        return false;
      }
      if (floors.length === 2) {
        if (
          t.floor.toLowerCase() === floors[0].name.toLowerCase() ||
          t.floor.toLowerCase() === floors[1].name.toLowerCase()
        )
          return true;
        return false;
      }
      if (floors.length === 1) {
        if (t.floor.toLowerCase() === floors[0].name.toLowerCase()) return true;
        return false;
      }
      return false;
    }

    return false;
  };

  private isDependsONFilters = (t: any): boolean => {
    const charts = this.charts;
    if (charts.value1 && charts.value2 && charts.value3) {
      if (
        t.over20 &&
        t.ma20 > -1 &&
        t.over50 &&
        t.ma50 > -1 &&
        t.over200 &&
        t.ma200 > -1
      ) {
        return true;
      }
      return false;
    } else if (charts.value1 && charts.value2) {
      if (t.over20 && t.ma20 > -1 && t.over50 && t.ma50 > -1) {
        return true;
      }
      return false;
    } else if (charts.value1 && charts.value3) {
      if (t.over20 && t.ma20 > -1 && t.over200 && t.ma200 > -1) {
        return true;
      }
      return false;
    } else if (charts.value2 && charts.value3) {
      if (t.over50 && t.ma50 > -1 && t.over200 && t.ma200 > -1) {
        return true;
      }
      return false;
    } else if (charts.value1) {
      return t.over20 && t.ma20 > -1;
    } else if (charts.value2) {
      return t.over50 && t.ma50 > -1;
    } else if (charts.value3) {
      return t.over200 && t.ma200 > -1;
    } else return true;
  };

  @action
  public loadDataTable = async () => {
    this.loadingTable = true;
    let th = 6;
    switch (this.timePeroid) {
      case '1m':
        th = 1;
        break;
      case '3m':
        th = 3;
        break;
      case '6m':
        th = 6;
        break;
      case '1y':
        th = 12;
        break;

      default:
        break;
    }
    const { floors, min_adClose, max_adClose, min_MA20, max_MA20 } =
      this.filters;
    let flsn: string[] = [];
    flsn = floors.slice().map((f) => f.name);
    let cc = [];
    if (this.charts.value1) cc.push('ma20');
    if (this.charts.value2) cc.push('ma50');
    if (this.charts.value3) cc.push('ma200');
    let minmaxCLose = [0, 500];
    let minmaxMA20 = [0, 200000];
    if (min_adClose !== undefined && max_adClose !== undefined) {
      minmaxCLose = [min_adClose, max_adClose];
    }
    if (min_MA20 !== undefined && max_MA20 !== undefined) {
      minmaxMA20 = [min_MA20, max_MA20];
    }
    const queryString: string = `${this.apiPath}?t=${th}&floor=${flsn.join(
      ',',
    )}&min_adClose=${minmaxCLose[0]}&max_adClose=${minmaxCLose[1]}&min_MA20=${
      minmaxMA20[0]
    }&max_MA20=${minmaxMA20[1]}&charts=${cc.join(',')}&list=1`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataTableSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadData = async (
    isFirstLoad: boolean = false,
    isLoading: boolean = true,
  ) => {
    this.loading = isLoading;
    let th = 6;
    switch (this.timePeroid) {
      case '1m':
        th = 1;
        break;
      case '3m':
        th = 3;
        break;
      case '6m':
        th = 6;
        break;
      case '1y':
        th = 12;
        break;

      default:
        break;
    }
    const { floors, min_adClose, max_adClose, min_MA20, max_MA20 } =
      this.filters;
    let flsn: string[] = [];
    flsn = floors.slice().map((f) => f.name);
    let minmaxCLose = [0, 500];
    let minmaxMA20 = [0, 200000];
    if (min_adClose !== undefined && max_adClose !== undefined) {
      minmaxCLose = [min_adClose, max_adClose];
    }
    if (min_MA20 !== undefined && max_MA20 !== undefined) {
      minmaxMA20 = [min_MA20, max_MA20];
    }
    const queryString: string = `${this.apiPath}?t=${th}&floor=${flsn.join(
      ',',
    )}&min_adClose=${minmaxCLose[0]}&max_adClose=${minmaxCLose[1]}&min_MA20=${
      minmaxMA20[0]
    }&max_MA20=${minmaxMA20[1]}`;
    await api
      .get(queryString)
      .then((resp) => resp.data.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
    if (isFirstLoad) {
      this.loadDataEnNote();
    }
  };

  @action
  public loadDataEnNote = () => {
    let th = 6;
    switch (this.timePeroid) {
      case '1m':
        th = 1;
        break;
      case '3m':
        th = 3;
        break;
      case '6m':
        th = 6;
        break;
      case '1y':
        th = 12;
        break;

      default:
        break;
    }
    const { floors, min_adClose, max_adClose, min_MA20, max_MA20 } =
      this.filters;
    let flsn: string[] = [];
    flsn = floors.slice().map((f) => f.name);
    let cc = [];
    if (this.charts.value1) cc.push('ma20');
    if (this.charts.value2) cc.push('ma50');
    if (this.charts.value3) cc.push('ma200');
    let minmaxCLose = [0, 500];
    let minmaxMA20 = [0, 200000];
    if (min_adClose !== undefined && max_adClose !== undefined) {
      minmaxCLose = [min_adClose, max_adClose];
    }
    if (min_MA20 !== undefined && max_MA20 !== undefined) {
      minmaxMA20 = [min_MA20, max_MA20];
    }
    const queryString: string = `${this.apiPath}?t=${th}&floor=${flsn.join(
      ',',
    )}&min_adClose=${minmaxCLose[0]}&max_adClose=${minmaxCLose[1]}&min_MA20=${
      minmaxMA20[0]
    }&max_MA20=${minmaxMA20[1]}&charts=${cc.join(',')}&latest=1`;
    api
      .get(queryString)
      .then((resp) => resp.data.data)
      .then(this.fetchDataEndNoteSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataEndNoteSuccess = (data: any) => {
    runInAction(() => {
      let ma20bk = this.data.data.ma20.slice();
      let ma50bk = this.data.data.ma50.slice();
      let ma200bk = this.data.data.ma200.slice();

      if (
        data &&
        ma20bk.length > 0 &&
        ma50bk.length > 0 &&
        ma200bk.length > 0
      ) {
        if (data.ma20 && data.ma20.length > 0) {
          const endNode20 = ma20bk[ma20bk.length - 1];
          if (endNode20.x !== data.ma20[0].date) {
            ma20bk.push({
              x: data.ma20[0].date,
              y: parseFloat(
                (
                  (parseInt(data.ma20[0].value) / data.ma20[0].total) *
                  100
                ).toFixed(2),
              ),
              value: parseInt(data.ma20[0].value),
              total: data.ma20[0].total,
            });
          } else if (
            endNode20.y !==
            parseFloat(
              (
                (parseInt(data.ma20[0].value) / data.ma20[0].total) *
                100
              ).toFixed(2),
            )
          ) {
            const ma20bk1 = ma20bk.filter((t) => t.x !== endNode20.x);
            ma20bk = [
              ...ma20bk1,
              {
                x: endNode20.x,
                y: parseFloat(
                  (
                    (parseInt(data.ma20[0].value) / data.ma20[0].total) *
                    100
                  ).toFixed(2),
                ),
                value: parseInt(data.ma20[0].value),
                total: data.ma20[0].total,
              },
            ];
          }
        }
        if (data.ma50 && data.ma50.length > 0) {
          const endNode50 = ma50bk[ma50bk.length - 1];
          if (endNode50.x !== data.ma50[0].date) {
            ma50bk.push({
              x: data.ma50[0].date,
              y: parseFloat(
                (
                  (parseInt(data.ma50[0].value) / data.ma50[0].total) *
                  100
                ).toFixed(2),
              ),
              value: parseInt(data.ma50[0].value),
              total: data.ma50[0].total,
            });
          } else if (
            endNode50.y !==
            parseFloat(
              (
                (parseInt(data.ma50[0].value) / data.ma50[0].total) *
                100
              ).toFixed(2),
            )
          ) {
            const ma50bk1 = ma50bk.filter((t) => t.x !== endNode50.x);
            ma50bk = [
              ...ma50bk1,
              {
                x: endNode50.x,
                y: parseFloat(
                  (
                    (parseInt(data.ma50[0].value) / data.ma50[0].total) *
                    100
                  ).toFixed(2),
                ),
                value: parseInt(data.ma50[0].value),
                total: data.ma50[0].total,
              },
            ];
          }
        }
        if (data.ma200 && data.ma200.length > 0) {
          const endNode200 = ma200bk[ma200bk.length - 1];
          if (endNode200.x !== data.ma200[0].date) {
            ma200bk.push({
              x: data.ma200[0].date,
              y: parseFloat(
                (
                  (parseInt(data.ma200[0].value) / data.ma200[0].total) *
                  100
                ).toFixed(2),
              ),
              value: parseInt(data.ma200[0].value),
              total: data.ma200[0].total,
            });
          } else if (
            endNode200.y !==
            parseFloat(
              (
                (parseInt(data.ma200[0].value) / data.ma200[0].total) *
                100
              ).toFixed(2),
            )
          ) {
            const ma200bk1 = ma200bk.filter((t) => t.x !== endNode200.x);
            ma200bk = [
              ...ma200bk1,
              {
                x: endNode200.x,
                y: parseFloat(
                  (
                    (parseInt(data.ma200[0].value) / data.ma200[0].total) *
                    100
                  ).toFixed(2),
                ),
                value: parseInt(data.ma200[0].value),
                total: data.ma200[0].total,
              },
            ];
          }
        }
      }
      const minX =
        this.data.data.ma20 && this.data.data.ma20.length > 0
          ? this.data.data.ma20[0].x
          : 0;

      const dataChart = {
        ma20: ma20bk,
        ma50: ma50bk,
        ma200: ma200bk,
      };

      const ma20C = dataChart.ma20.slice();
      const ma50C = dataChart.ma50.slice();
      const ma200C = dataChart.ma200.slice();

      const ma20SortY = this.sortY(ma20C);
      const ma50SortY = this.sortY(ma50C);
      const ma200SortY = this.sortY(ma200C);
      const min11Y = ma20SortY.length > 0 ? ma20SortY[0].y : 0;
      const min21Y = ma50SortY.length > 0 ? ma50SortY[0].y : 0;
      const min31Y = ma200SortY.length > 0 ? ma200SortY[0].y : 0;
      const minY = [min11Y, min21Y, min31Y].sort((a, b) => a - b)[0];
      const max11Y =
        ma20SortY.length > 0 ? ma20SortY[ma20SortY.length - 1].y : 0;
      const max21Y =
        ma50SortY.length > 0 ? ma50SortY[ma50SortY.length - 1].y : 0;
      const max31Y =
        ma200SortY.length > 0 ? ma200SortY[ma200SortY.length - 1].y : 0;
      const maxY = [max11Y, max21Y, max31Y].sort((a, b) => b - a)[0];
      const convertData: PTTBChartDataNode = {
        minX: minX,
        min1Y: minY,
        max1Y: maxY,
        data: dataChart,
      };
      this.data = convertData;
      this.loading = false;
      const time = moment().format(StatusDateFormat);
      this.statusTime = time.toString();
    });
  };

  @action
  private fetchDataTableSuccess = (data: any) => {
    this.dataTable = data;
    this.loadingTable = false;
    const time = moment().format(StatusDateFormat);
    this.statusTimeTable = time.toString();
  };

  @action
  private fetchDataSuccess = (data: any) => {
    runInAction(() => {
      const minX = data.ma20 && data.ma20.length > 0 ? data.ma20[0].date : 0;
      const dataChart = {
        ma20: data.ma20.map((t: any) => ({
          x: t.date,
          y: parseFloat(((parseInt(t.value) / t.total) * 100).toFixed(2)),
          value: parseInt(t.value),
          total: t.total,
        })),
        ma50: data.ma50.map((t: any) => ({
          x: t.date,
          y: parseFloat(((parseInt(t.value) / t.total) * 100).toFixed(2)),
          value: parseInt(t.value),
          total: t.total,
        })),
        ma200: data.ma200.map((t: any) => ({
          x: t.date,
          y: parseFloat(((parseInt(t.value) / t.total) * 100).toFixed(2)),
          value: parseInt(t.value),
          total: t.total,
        })),
      };
      const ma20C = dataChart.ma20.slice();
      const ma50C = dataChart.ma50.slice();
      const ma200C = dataChart.ma200.slice();

      const ma20SortY = this.sortY(ma20C);
      const ma50SortY = this.sortY(ma50C);
      const ma200SortY = this.sortY(ma200C);
      const min11Y = ma20SortY.length > 0 ? ma20SortY[0].y : 0;
      const min21Y = ma50SortY.length > 0 ? ma50SortY[0].y : 0;
      const min31Y = ma200SortY.length > 0 ? ma200SortY[0].y : 0;
      const minY = [min11Y, min21Y, min31Y].sort((a, b) => a - b)[0];
      const max11Y =
        ma20SortY.length > 0 ? ma20SortY[ma20SortY.length - 1].y : 0;
      const max21Y =
        ma50SortY.length > 0 ? ma50SortY[ma50SortY.length - 1].y : 0;
      const max31Y =
        ma200SortY.length > 0 ? ma200SortY[ma200SortY.length - 1].y : 0;
      const maxY = [max11Y, max21Y, max31Y].sort((a, b) => b - a)[0];
      const convertData: PTTBChartDataNode = {
        minX: minX,
        min1Y: minY,
        max1Y: maxY,
        data: dataChart,
      };
      this.data = convertData;
      this.loading = false;
      const time = moment().format(StatusDateFormat);
      this.statusTime = time.toString();
    });
  };

  @action
  private fetchDataError = (error: any) => {
    runInAction(() => {
      this.loading = false;
      this.loadingTable = false;
    });
  };

  private sortY = (arr: any) => {
    const data = arr.sort((a: any, b: any) => a.y - b.y);
    return data;
  };
}

export default new PTTBChartStore();
