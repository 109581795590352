import { getColorsRRG } from '../utils/StringFormat';

const colors = [
  '#ff6384',
  '#36a2eb',
  '#ffce56',
  '#4bc0c0',
  '#ff9f40',
  '#9966ff',
  '#f7464a',
  '#47b275',
  '#e040fb',
  '#caff70',
  '#008080',
  '#ffe1ff',
  '#0000ff',
  '#eecbad',
  '#e8e8e8',
];

const randomColor = (index: number, id?: string) => {
  let color = 'white';
  if (colors[index]) color = colors[index];
  else color = getColorsRRG(id || index.toString());
  return color;
};

export const configColors = {
  color: '#F2BB0E',
  AppTableCellSocket: {
    statusAdCloseUp: 'rgba(13, 238, 13, 0.47)',
    statusAdCloseDown: 'rgba(255, 0, 0, 0.47)',
    statusNmVolumeUp: 'rgba(128,128,128,0.8)',
  },
  loading: {
    spinner: '#F2BB0E',
    overlay: 'background.paper',
  },
  blockStatus: {
    text: 'text.secondary',
  },
  styledBadge: {
    background: '#FF810D',
    color: '#ffffff',
  },
  appSlider: {
    rail: '#bfbfbf',
    color: '#3880ff',
    thumb: '#fff',
    valueLabelDark: '#fff',
    valueLabelLight: '#000',
  },
};

export default randomColor;
