import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import AppUserIcon from './icons/AppUserIcon';
import AppLockIcon from './icons/AppLockIcon';
import { getCharacter } from '../../utils/StringFormat';

export const AppUserInfoButton: React.FC<{ userData: any }> = ({
  userData,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const logoutHandle = () => {
    navigate('/logout');
  };
  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <Button
          variant="text"
          onClick={handleClick}
          size="small"
          sx={{ textTransform: 'unset', ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="secondary"
        >
          <Avatar sx={{ width: 32, height: 32 }}>
            {getCharacter(userData.name)}
          </Avatar>
          <Typography pl={1} pr={1} fontSize={14}>
            {userData.name}
          </Typography>
          <KeyboardArrowDownIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            border: '1px solid #ffffff33',
            overflow: 'visible',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ fontSize: 13 }} onClick={() => navigate('/user-info')}>
          <ListItemIcon>
            <AppUserIcon fontSize="small" />
          </ListItemIcon>
          Trang cá nhân
        </MenuItem>
        <MenuItem sx={{ fontSize: 13 }} onClick={() => navigate('/reset')}>
          <ListItemIcon>
            <AppLockIcon fontSize="small" />
          </ListItemIcon>
          Đổi mật khẩu
        </MenuItem>
        <Divider />
        <MenuItem sx={{ fontSize: 13 }} onClick={logoutHandle}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Đăng xuất
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
