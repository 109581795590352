import { Chart } from '../store/CorrelationGraph/TabFinanceStore';

export const tabTaiChinhsdefaultBANK: Array<Chart> = [
  {
    id: '41',
    chartname: 'Cấu trúc tài sản',
    labels: ['Cấu trúc tài sản'],
    type: 'default',
    chartconfig: [
      { type: 'stackedbar', dataKey: 1, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 2, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 3, color: '#58d764' },
      { type: 'stackedbar', dataKey: 4, color: '#ffd900' },
      { type: 'stackedbar', dataKey: 5, color: '#ff0000' },
      { type: 'stackedbar', dataKey: 6, color: '#ff8383' },
      { type: 'stackedbar', dataKey: 7, color: '#ff1ea5' },
      { type: 'stackedbar', dataKey: 8, color: '#1ed6ff' },
      { type: 'stackedbar', dataKey: 9, color: '#f6f982' },
      { type: 'stackedbar', dataKey: 10, color: '#068b78' },
      { type: 'stackedbar', dataKey: 11, color: '#ff4a11' },
    ],
  },
  {
    id: '42',
    type: 'default',
    chartname: 'Cấu trúc nguồn vốn',
    labels: ['Cấu trúc nguồn vốn'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 13, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 14, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 15, color: '#58d764' },
      { type: 'stackedbar', dataKey: 16, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 17, color: '#ffd900' },
      { type: 'stackedbar', dataKey: 18, color: '#ff0000' },
      { type: 'stackedbar', dataKey: 19, color: '#ff8383' },
      { type: 'stackedbar', dataKey: 20, color: '#ff1ea5' },
      { type: 'stackedbar', dataKey: 21, color: '#1ed6ff' },
      { type: 'stackedbar', dataKey: 22, color: '#f6f982' },
    ],
  },
  {
    id: '43',
    type: 'default',
    chartname: 'Thu nhập lãi thuần',
    isSelected: true,
    labels: ['Thu nhập lãi thuần'],
    chartconfig: [
      { type: 'bar', dataKey: 23, color: '#237fd3' },
      { type: 'line', dataKey: 24, color: '#ffd900' },
    ],
  },
  {
    id: '44',
    type: 'default',
    chartname: 'Lợi nhuận sau thuế',
    isSelected: true,
    labels: ['Lợi nhuận sau thuế'],
    chartconfig: [
      { type: 'bar', dataKey: 25, color: '#237fd3' },
      { type: 'line', dataKey: 26, color: '#ffd900' },
    ],
  },
  {
    id: '45',
    type: 'default',
    chartname: 'Cấu trúc Tổng Thu nhập hoạt động',
    isSelected: true,
    labels: ['Cấu trúc Tổng Thu nhập hoạt động'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 27, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 28, color: '#1a2cca' },
      { type: 'line', dataKey: 29, color: '#58d764' },
    ],
  },
  {
    id: '46',
    type: 'default',
    chartname: 'Cấu trúc Thu nhập ngoài lãi',
    isSelected: true,
    labels: ['Cấu trúc Thu nhập ngoài lãi'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 30, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 31, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 32, color: '#58d764' },
      { type: 'stackedbar', dataKey: 33, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 34, color: '#ffd900' },
      { type: 'stackedbar', dataKey: 35, color: '#ff0000' },
      { type: 'line', dataKey: 36, color: '#ff8383' },
    ],
  },
  {
    id: '47',
    type: 'default',
    chartname: 'Chỉ số định giá P/B',
    isSelected: true,
    labels: ['Chỉ số định giá P/B'],
    chartconfig: [
      { type: 'line', dataKey: 37, color: '#ffce56' },
      { type: 'bar', dataKey: 38, color: '#f7464a' },
    ],
  },
  {
    id: '48',
    type: 'default',
    chartname: 'Hiệu quả sử dụng vốn',
    isSelected: true,
    labels: ['Hiệu quả sử dụng vốn'],
    chartconfig: [
      { type: 'line', dataKey: 39, color: '#237fd3' },
      { type: 'line', dataKey: 40, color: '#ffd900' },
    ],
  },
  {
    id: '49',
    type: 'default',
    chartname: 'Cơ cấu Nợ theo nhóm',
    isSelected: true,
    labels: ['Cơ cấu Nợ theo nhóm'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 41, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 42, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 43, color: '#58d764' },
      { type: 'stackedbar', dataKey: 44, color: '#ff4a11' },
      { type: 'line', dataKey: 45, color: '#ffd900' },
    ],
  },
  {
    id: '50',
    chartname: 'Cho vay phân theo thời gian đáo hạn',
    isSelected: true,
    labels: ['Cho vay phân theo thời gian đáo hạn'],
    type: 'default',
    chartconfig: [
      { type: 'stackedbar', dataKey: 46, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 47, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 48, color: '#58d764' },
    ],
  },
  {
    id: '51',
    chartname: 'Cơ cấu tiền gửi',
    isSelected: true,
    labels: ['Cơ cấu tiền gửi'],
    type: 'default',
    chartconfig: [
      { type: 'stackedbar', dataKey: 49, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 50, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 51, color: '#58d764' },
      { type: 'stackedbar', dataKey: 52, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 53, color: '#ffd900' },
    ],
  },
  {
    id: '52',
    chartname: 'Cho vay phân theo ngành nghề kinh doanh',
    isSelected: true,
    labels: ['Cho vay phân theo ngành nghề kinh doanh'],
    type: 'default',
    chartconfig: [
      { type: 'stackedbar', dataKey: 54, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 55, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 56, color: '#58d764' },
      { type: 'stackedbar', dataKey: 57, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 58, color: '#ffd900' },
      { type: 'stackedbar', dataKey: 59, color: '#ff0000' },
      { type: 'stackedbar', dataKey: 60, color: '#ff8383' },
      { type: 'stackedbar', dataKey: 61, color: '#ff1ea5' },
      { type: 'stackedbar', dataKey: 62, color: '#1ed6ff' },
      { type: 'stackedbar', dataKey: 63, color: '#f6f982' },
      { type: 'stackedbar', dataKey: 64, color: '#068b78' },
    ],
  },
  {
    id: '53',
    chartname: 'Các khoản dự phòng',
    isSelected: true,
    labels: ['Các khoản dự phòng'],
    type: 'default',
    chartconfig: [
      { type: 'stackedbar', dataKey: 65, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 66, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 67, color: '#58d764' },
      { type: 'stackedbar', dataKey: 68, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 69, color: '#ffd900' },
    ],
  },
  {
    id: '54',
    type: 'default',
    chartname: 'Lịch sử hoàn thành kế hoạch DT',
    labels: ['Lịch sử hoàn thành kế hoạch DT'],
    peroidDefault: 1,
    chartconfig: [
      { type: 'bar', dataKey: 71, color: '#47b275' },
      { type: 'bar', dataKey: 70, color: '#9966ff' },
      { type: 'line', dataKey: 72, color: '#e040fb' },
    ],
  },
  {
    id: '55',
    type: 'default',
    chartname: 'Lịch sử hoàn thành kế hoạch LN',
    isSelected: true,
    peroidDefault: 1,
    labels: ['Lịch sử hoàn thành kế hoạch LN'],
    chartconfig: [
      { type: 'bar', dataKey: 74, color: '#e8e8e8' },
      { type: 'bar', dataKey: 73, color: '#f7464a' },
      { type: 'line', dataKey: 75, color: '#47b275' },
    ],
  },
];
