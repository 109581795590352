import { Chart } from '../store/CorrelationGraph/TabFinanceStore';

export const tabTaiChinhsdefaultNON_FINANCE: Array<Chart> = [
  {
    id: '17',
    chartname: 'Cấu trúc tài sản',
    labels: ['Cấu trúc tài sản'],
    type: 'default',
    chartconfig: [
      { type: 'stackedbar', dataKey: 5, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 6, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 7, color: '#58d764' },
      { type: 'stackedbar', dataKey: 8, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 9, color: '#ffd900' },
      { type: 'stackedbar', dataKey: 10, color: '#ff0000' },
      { type: 'stackedbar', dataKey: 11, color: '#ff8383' },
      { type: 'stackedbar', dataKey: 12, color: '#ff1ea5' },
      { type: 'stackedbar', dataKey: 13, color: '#1ed6ff' },
      { type: 'stackedbar', dataKey: 14, color: '#f6f982' },
      { type: 'stackedbar', dataKey: 15, color: '#068b78' },
    ],
  },
  {
    id: '18',
    type: 'default',
    chartname: 'Cấu trúc nguồn vốn',
    labels: ['Cấu trúc nguồn vốn'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 16, color: '#237fd3' },
      { type: 'stackedbar', dataKey: 17, color: '#1a2cca' },
      { type: 'stackedbar', dataKey: 18, color: '#58d764' },
      { type: 'stackedbar', dataKey: 19, color: '#ff4a11' },
      { type: 'stackedbar', dataKey: 20, color: '#ffd900' },
      { type: 'stackedbar', dataKey: 21, color: '#ff0000' },
      { type: 'stackedbar', dataKey: 22, color: '#ff8383' },
      { type: 'stackedbar', dataKey: 23, color: '#ff1ea5' },
      { type: 'stackedbar', dataKey: 24, color: '#1ed6ff' },
      { type: 'stackedbar', dataKey: 25, color: '#f6f982' },
    ],
  },
  {
    id: '15',
    chartname: 'Doanh thu thuần',
    isSelected: true,
    labels: ['Doanh thu thuần'],
    type: 'default',
    chartconfig: [
      { type: 'bar', dataKey: 1, color: '#237fd3' },
      { type: 'line', dataKey: 2, color: '#d4b609' },
    ],
  },
  {
    id: '16',
    type: 'default',
    chartname: 'Lợi nhuận sau thuế',
    isSelected: true,
    labels: ['Lợi nhuận sau thuế'],
    chartconfig: [
      { type: 'bar', dataKey: 3, color: '#237fd3' },
      { type: 'line', dataKey: 4, color: '#ffd900' },
    ],
  },
  {
    id: '19',
    type: 'default',
    chartname: 'Hiệu quả sử dụng vốn',
    isSelected: true,
    labels: ['Hiệu quả sử dụng vốn'],
    chartconfig: [
      { type: 'line', dataKey: 26, color: '#237fd3' },
      { type: 'line', dataKey: 27, color: '#ffd900' },
    ],
  },
  {
    id: '20',
    type: 'default',
    chartname: 'Biên lợi nhuận',
    isSelected: true,
    labels: ['Biên lợi nhuận'],
    chartconfig: [
      { type: 'line', dataKey: 28, color: '#237fd3' },
      { type: 'line', dataKey: 29, color: '#58d764' },
      { type: 'line', dataKey: 30, color: '#ff4a11' },
      { type: 'line', dataKey: 31, color: '#e040fb' },
      { type: 'line', dataKey: 32, color: '#ff8383' },
    ],
  },
  {
    id: '22',
    type: 'default',
    chartname: 'Lưu chuyển tiền',
    isSelected: true,
    labels: ['Lưu chuyển tiền'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 33, color: '#ffce56' },
      { type: 'stackedbar', dataKey: 34, color: '#47b275' },
      { type: 'stackedbar', dataKey: 35, color: '#9966ff' },
      { type: 'line', dataKey: 36, color: '#e040fb' },
    ],
  },
  {
    id: '23',
    type: 'default',
    chartname: 'Cấu trúc lợi nhuận trước thuế',
    isSelected: true,
    labels: ['Cấu trúc lợi nhuận trước thuế'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 37, color: '#47b275' },
      { type: 'stackedbar', dataKey: 38, color: '#9966ff' },
      { type: 'stackedbar', dataKey: 39, color: '#ffce56' },
      { type: 'stackedbar', dataKey: 40, color: '#f7464a' },
    ],
  },
  {
    id: '24',
    type: 'default',
    chartname: 'Cấu trúc chi phí',
    isSelected: true,
    labels: ['Cấu trúc chi phí'],
    chartconfig: [
      { type: 'stackedbar', dataKey: 41, color: '#47b275' },
      { type: 'stackedbar', dataKey: 42, color: '#9966ff' },
      { type: 'stackedbar', dataKey: 43, color: '#f7464a' },
      { type: 'stackedbar', dataKey: 44, color: '#e040fb' },
      { type: 'stackedbar', dataKey: 45, color: '#ffce56' },
    ],
  },
  {
    id: '21',
    type: 'default',
    chartname: 'Chỉ số chi phí / Doanh thu thuần',
    isSelected: true,
    labels: ['Chỉ số chi phí / Doanh thu thuần'],
    chartconfig: [
      { type: 'line', dataKey: 46, color: '#47b275' },
      { type: 'line', dataKey: 47, color: '#9966ff' },
      { type: 'line', dataKey: 48, color: '#f7464a' },
      { type: 'line', dataKey: 49, color: '#237fd3' },
      { type: 'line', dataKey: 50, color: '#ffce56' },
    ],
  },
  {
    id: '25',
    type: 'default',
    chartname: 'Lịch sử hoàn thành kế hoạch DT',
    labels: ['Lịch sử hoàn thành kế hoạch DT'],
    peroidDefault: 1,
    chartconfig: [
      { type: 'bar', dataKey: 52, color: '#47b275' },
      { type: 'bar', dataKey: 51, color: '#9966ff' },
      { type: 'line', dataKey: 53, color: '#f7464a' },
    ],
  },
  {
    id: '26',
    type: 'default',
    chartname: 'Lịch sử hoàn thành kế hoạch LN',
    isSelected: true,
    labels: ['Lịch sử hoàn thành kế hoạch LN'],
    peroidDefault: 1,
    chartconfig: [
      { type: 'bar', dataKey: 55, color: '#e8e8e8' },
      { type: 'bar', dataKey: 54, color: '#f7464a' },
      { type: 'line', dataKey: 56, color: '#47b275' },
    ],
  },
  {
    id: '27',
    type: 'default',
    chartname: 'Tỷ lệ nợ',
    isSelected: true,
    labels: ['Tỷ lệ nợ'],
    chartconfig: [
      { type: 'bar', dataKey: 57, color: '#ffce56' },
      { type: 'line', dataKey: 58, color: '#f7464a' },
    ],
  },
  {
    id: '28',
    type: 'default',
    chartname: 'Chỉ số định giá P/E',
    isSelected: true,
    labels: ['Chỉ số định giá P/E'],
    chartconfig: [
      { type: 'line', dataKey: 59, color: '#ffce56' },
      { type: 'bar', dataKey: 60, color: '#47b275' },
    ],
  },
  {
    id: '29',
    type: 'default',
    chartname: 'Chỉ số định giá P/B',
    labels: ['Chỉ số định giá P/B'],
    chartconfig: [
      { type: 'line', dataKey: 61, color: '#ffce56' },
      { type: 'bar', dataKey: 62, color: '#f7464a' },
    ],
  },
];
