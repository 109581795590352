import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export interface ListStockProps {
  listStock: Array<ListStockData>;
  loadData: () => void;
  loading: boolean;
}

export interface ListStockData {
  id: number;
  adChange: number;
  adClose: number;
  pctChange: number;
  name: string;
  code: string;
}

class ListStockStore implements ListStockProps {
  private apiStock: string = 'api/stock/list';

  @observable
  listStock: Array<ListStockData> = [];

  @observable
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadData = () => {
    this.loading = true;
    const queryString: string = `${this.apiStock}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: any) => {
    const convertData: Array<ListStockData> = data.map((d: any, index: any) => {
      const name: any[] = [d.code];
      if (d.shortName && d.shortName.trim() !== '')
        name.push(';' + d.shortName);
      if (d.floor && d.floor.trim() !== '') name.push(';' + d.floor);
      return {
        id: index + 1,
        code: d.code,
        name: name.join(''),
        adChange: d.adChange,
        adClose: d.adClose,
        pctChange: d.pctChange,
      };
    });
    this.listStock = convertData;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    this.loading = false;
  };
}

export default new ListStockStore();
