import { observable, action, makeAutoObservable } from 'mobx';
import api from '../../request';

export interface ThongKeChungStoreProps {
  data: {
    code: string;
    name: string;
    floor?: string;
    sectorName?: string;
    BVPS?: string;
    EPS?: string;
    KLCPLH?: number;
    MarketCapital?: number;
    PB?: string;
    PE?: string;
    ROAA?: string;
    ROEA?: string;
    adChange?: number;
    adClose?: number;
    epsRating?: number;
    pctChange?: number;
    rsRating?: string;
    rsnRating?: string;
    smrRating?: number;
    floorPrice: number;
    ceilingPrice: number;
    data: Array<{
      label: Array<number>;
      data: Array<{
        displayLevel: number;
        itemCode: number;
        itemName: string;
        data: Array<{
          year: number;
          value1: number;
          value2: number;
        }>;
      }>;
    }>;
  };
  code: string;
  loadData: () => void;
  setCode: (code: string, loadData?: boolean) => void;
  loading: boolean;
  updateData: (data: any) => void;
}

export interface ThongKeChungData {
  label: Array<number>;
  data: Array<{
    itemCode: string;
    displayLevel: number;
    itemName: string;
    data: Array<{
      year: number;
      value1: number;
      value2: number;
    }>;
  }>;
}

class ThongKeChungStore implements ThongKeChungStoreProps {
  private apiPath: string = 'api/company/statistics';

  @observable
  loading: boolean = true;

  @observable
  data: {
    code: string;
    name: string;
    floor?: string;
    sectorName?: string;
    BVPS?: string;
    EPS?: string;
    KLCPLH?: number;
    MarketCapital?: number;
    PB?: string;
    PE?: string;
    ROAA?: string;
    ROEA?: string;
    adChange?: number;
    adClose?: number;
    epsRating?: number;
    pctChange?: number;
    rsRating?: string;
    rsnRating?: string;
    smrRating?: number;
    floorPrice: number;
    ceilingPrice: number;
    data: Array<{
      label: Array<number>;
      data: Array<{
        displayLevel: number;
        itemCode: number;
        itemName: string;
        data: Array<{
          year: number;
          value1: number;
          value2: number;
        }>;
      }>;
    }>;
  } = {
    code: '',
    name: '',
    floorPrice: 0,
    ceilingPrice: 0,
    data: [],
  };

  @observable
  code: string = 'MBS';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public updateData = (data: any) => {
    this.data = data;
  };

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}?code=${this.code}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default ThongKeChungStore;
