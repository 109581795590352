const AppNotificationIcon = (props: any) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7956 11.385L17.2122 5.68834C16.7481 4.01945 15.7395 2.55358 14.3467 1.52373C12.9539 0.493884 11.2567 -0.0408381 9.52513 0.00456195C7.79351 0.049962 6.12675 0.672879 4.78979 1.7743C3.45284 2.87571 2.52241 4.39243 2.14641 6.08334L0.920574 11.5958C0.78517 12.205 0.788301 12.8367 0.929735 13.4444C1.07117 14.0522 1.34729 14.6204 1.73773 15.1071C2.12816 15.5939 2.62292 15.9867 3.1855 16.2566C3.74808 16.5266 4.36409 16.6667 4.98807 16.6667H5.91641C6.10767 17.6086 6.61868 18.4554 7.36286 19.0637C8.10704 19.6719 9.03861 20.0042 9.99974 20.0042C10.9609 20.0042 11.8924 19.6719 12.6366 19.0637C13.3808 18.4554 13.8918 17.6086 14.0831 16.6667H14.7814C15.4237 16.6667 16.0574 16.5183 16.6329 16.2329C17.2083 15.9475 17.71 15.5329 18.0988 15.0216C18.4875 14.5102 18.7527 13.9159 18.8737 13.285C18.9948 12.6542 18.9683 12.0039 18.7964 11.385H18.7956ZM9.99974 18.3333C9.48452 18.3312 8.98255 18.1699 8.56249 17.8716C8.14243 17.5733 7.82481 17.1524 7.65307 16.6667H12.3464C12.1747 17.1524 11.857 17.5733 11.437 17.8716C11.0169 18.1699 10.515 18.3312 9.99974 18.3333ZM16.7714 14.0125C16.5392 14.3206 16.2383 14.5702 15.8927 14.7416C15.5472 14.9131 15.1663 15.0015 14.7806 15H4.98807C4.61373 14.9999 4.24417 14.9158 3.90669 14.7538C3.56921 14.5918 3.27241 14.3561 3.03821 14.0641C2.80401 13.7721 2.63837 13.4312 2.55354 13.0665C2.46871 12.7019 2.46684 12.3229 2.54807 11.9575L3.77307 6.44417C4.06837 5.11601 4.79916 3.92468 5.84929 3.05955C6.89941 2.19442 8.20859 1.70515 9.56872 1.66952C10.9288 1.63389 12.2618 2.05394 13.3558 2.8629C14.4498 3.67186 15.242 4.8233 15.6064 6.13417L17.1897 11.8308C17.2944 12.202 17.311 12.5924 17.2384 12.9711C17.1658 13.3498 17.0059 13.7064 16.7714 14.0125Z"
        fill="white"
      />
    </svg>
  );
};
export default AppNotificationIcon;
