import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export const TimePeroidTypes = ['Ngày', 'Tuần'];
export const SectorTypes = ['Cổ phiếu nổi bật', 'Ngành nổi bật'];
export const NumberTypes = ['1', '3', '6', '9', '12', '15', '20'];

export interface RGGHomeChartProps {
  data: Array<TCTTData>;
  loadData: () => void;
  loading: boolean;
  isFit: boolean;
  minmaxIndex: number[];
  updateMinMaxIndex: (minmaxIndex: number[], isPlay?: boolean) => void;
  setFit: (isFit: boolean) => void;
  timePeroid: string;
  sectorParam: string;
  numberPhien: string;
  setNumberPhien: (numberPhien: string) => void;
  setTimePeroid: (timePeroid: string) => void;
  setSectorParam: (sectorParam: string) => void;
}

export interface TCTTData {
  selected: boolean;
  code: string;
  name: string;
  type: number;
  chart: {
    index: number;
    y: number;
    x: number;
    isFirst: boolean;
    DT: string;
    adChange: number;
    adClose: number;
    nmVolume: number;
    pctChange: number;
    volMa20: number;
  }[];
}

class RGGHomeChartStore implements RGGHomeChartProps {
  private apiPath: string = 'api/rrg';
  private apiPathSector: string = 'api/rrg/sector?code=';
  private apiPathStock: string = 'api/rrg/stock?code=';

  @observable
  data: Array<TCTTData> = [];

  @observable
  minmaxIndex: number[] = [44, 49];

  @observable
  isFit: boolean = true;

  @observable
  loading: boolean = false;

  @observable
  sectorParam: string = 'Cổ phiếu nổi bật';

  @observable
  timePeroid: string = 'Ngày';

  @observable
  numberPhien: string = '6';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setNumberPhien = (numberPhien: string) => {
    this.numberPhien = numberPhien;
    const phien = parseInt(numberPhien);
    this.minmaxIndex = [50 - phien, 49];
  };

  @action
  public setSectorParam = (sectorParam: string) => {
    this.sectorParam = sectorParam;
    this.loadData();
  };

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.timePeroid = timePeroid;
    this.loadData();
  };

  @action
  public setFit = (isFit: boolean) => (this.isFit = isFit);

  @action
  public updateMinMaxIndex = (minmaxIndex: number[]) => {
    this.minmaxIndex = minmaxIndex;
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const week = this.timePeroid === 'Ngày' ? '0' : '1';
    const sectorParam =
      this.sectorParam === 'Cổ phiếu nổi bật'
        ? 'VCB,SSI,VIC,GAS'
        : '8300,8600,8700';
    const queryString: string = `${this.apiPath}?week=${week}&codes=${sectorParam}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    if (dataR.length === 0) {
      this.loading = false;
      return;
    }

    this.data = dataR.map((t: any, _i: number) => {
      return {
        selected: _i < 10 ? true : false,
        ...t,
        chart: t.chart.map((k: any, index: number) => ({
          x: parseFloat(k.R),
          y: parseFloat(k.M),
          index: index,
          isFirst: index === 0,
          isEnd: false,
          adClose: k.adClose,
          pctChange: k.pctChange,
          nmVolume: k.nmVolume,
          volMa20: k.volMa20,
          date: k.DT,
        })),
      };
    });

    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default new RGGHomeChartStore();
