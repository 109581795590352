

const AppLogo = (props: any) => {

  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 29"
      width="32"
      height="29"
      style={{ cursor: 'pointer' }}
      onClick={() => window.open('https://alphastock.vn', '_blank')}
    >
      <defs>
        <linearGradient
          id="g1"
          x1="35.2"
          y1="22.6"
          x2="3.5"
          y2="36.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g2"
          x1="19.3"
          y1="-14.6"
          x2="39.2"
          y2="21.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g3"
          x1="1.6"
          y1="7.4"
          x2="16.7"
          y2="7.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g4"
          x1="21.8"
          y1="-4"
          x2="38.9"
          y2="27.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g5"
          x1="15.7"
          y1="-3.9"
          x2="30.2"
          y2="22.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g6"
          x1="-51.1"
          y1="24"
          x2="-81.9"
          y2="37.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g7"
          x1="-66.5"
          y1="-12.2"
          x2="-47.2"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g8"
          x1="-83.7"
          y1="9.2"
          x2="-69.1"
          y2="9.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g9"
          x1="-64.1"
          y1="-1.9"
          x2="-47.5"
          y2="28.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
        <linearGradient
          id="g10"
          x1="-70"
          y1="-1.8"
          x2="-56"
          y2="23.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#f5d620" stopOpacity=".9" />
          <stop offset="1" stopColor="#ba6930" />
        </linearGradient>
      </defs>
      <g id="Background"></g>
      <g id="Name, tagline">
        <path fill="#231f20" d="m-110.8-17.9h79.9v78.9h-79.9z" />
        <g>
          <path
            fillRule="evenodd"
            fill="#f2bb0e"
            d="m-89.1 40.9h-1.3q-0.3 0.5-0.3 0.7 0 0.1 0.1 0.1 0.1 0.1 0.4 0.1v0.2h-1.4v-0.2q0.2 0 0.2-0.1 0.1 0 0.2 0 0.1-0.1 0.1-0.1 0.1-0.1 0.1-0.2l1.3-3.1h0.3l1.2 2.7q0.1 0.3 0.1 0.4 0.1 0.2 0.2 0.2 0.1 0.1 0.2 0.1 0.1 0.1 0.2 0.1v0.2h-1.8v-0.2q0.2 0 0.3-0.1 0.1 0 0.1-0.1 0-0.1 0-0.2 0-0.1-0.1-0.2zm-1.2-0.3h1.1l-0.5-1.5h-0.1z"
          />
          <path
            fill="#f2bb0e"
            d="m-83.8 42h-3.3v-0.2q0.2 0 0.3 0 0.1-0.1 0.1-0.1 0.1-0.1 0.1-0.2 0-0.1 0-0.3v-2.1q0-0.2 0-0.3 0-0.1-0.1-0.2 0 0-0.1-0.1-0.1 0-0.2 0v-0.2h1.6v0.2q-0.1 0-0.2 0-0.1 0.1-0.2 0.1 0 0.1-0.1 0.2 0 0.1 0 0.3v2q0 0.2 0.1 0.3 0 0.1 0.1 0.2 0.1 0.1 0.3 0.1 0.1 0 0.4 0 0.3 0 0.5 0 0.1-0.1 0.3-0.1 0.1-0.1 0.2-0.3 0-0.2 0.1-0.4h0.2z"
          />
          <path
            fillRule="evenodd"
            fill="#f2bb0e"
            d="m-79.9 39.3q0 0.6-0.4 0.8-0.4 0.3-1.2 0.3h-0.4v0.8q0 0.2 0 0.3 0 0.1 0.1 0.2 0.1 0 0.2 0.1 0.2 0 0.4 0v0.2h-1.9v-0.2q0.1 0 0.2 0 0.1-0.1 0.2-0.1 0.1-0.1 0.1-0.2 0-0.1 0-0.3v-2.1q0-0.2 0-0.3 0-0.1-0.1-0.2 0 0-0.1-0.1-0.1 0-0.3 0v-0.2h1.7q0.7 0 1.1 0.3 0.4 0.2 0.4 0.7zm-2 0.8h0.3q0.5 0 0.7-0.2 0.2-0.2 0.2-0.6 0-0.3-0.2-0.5-0.2-0.2-0.5-0.2h-0.4q-0.1 0-0.1 0.1z"
          />
          <path
            fill="#f2bb0e"
            d="m-75.6 41.2q0 0.2 0 0.3 0 0.1 0.1 0.2 0 0 0.1 0.1 0.1 0 0.3 0v0.2h-1.8v-0.2q0.2 0 0.3 0 0.1-0.1 0.2-0.1 0-0.1 0.1-0.2 0-0.1 0-0.3v-0.9h-1.8v0.9q0 0.2 0 0.3 0 0.1 0.1 0.2 0 0 0.2 0.1 0.1 0 0.2 0v0.2h-1.7v-0.2q0.1 0 0.2 0 0.1-0.1 0.2-0.1 0.1-0.1 0.1-0.2 0-0.1 0-0.3v-2.1q0-0.2 0-0.3 0-0.1-0.1-0.2 0 0-0.1-0.1-0.1 0-0.3 0v-0.2h1.7v0.2q-0.2 0-0.3 0-0.1 0.1-0.1 0.1-0.1 0.1-0.1 0.2 0 0.1 0 0.3v0.9h1.8v-0.9q0-0.2 0-0.3-0.1-0.1-0.1-0.2-0.1 0-0.2-0.1-0.1 0-0.2 0v-0.2h1.6v0.2q-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.1-0.1 0.2 0 0.1 0 0.3z"
          />
          <path
            fillRule="evenodd"
            fill="#f2bb0e"
            d="m-72.2 40.9h-1.3q-0.3 0.5-0.3 0.7 0 0.1 0.1 0.1 0.1 0.1 0.4 0.1v0.2h-1.4v-0.2q0.2 0 0.2-0.1 0.1 0 0.2 0 0.1-0.1 0.1-0.1 0.1-0.1 0.1-0.2l1.3-3.1h0.3l1.2 2.7q0.1 0.3 0.1 0.4 0.1 0.2 0.2 0.2 0.1 0.1 0.2 0.1 0.1 0.1 0.2 0.1v0.2h-1.8v-0.2q0.2 0 0.3-0.1 0.1 0 0.1-0.1 0-0.1 0-0.2 0-0.1-0.1-0.2zm-1.2-0.3h1.1l-0.5-1.5h-0.1z"
          />
          <path
            fill="#f2bb0e"
            d="m-68.7 40.8h0.2q0.1 0.4 0.4 0.7 0.3 0.2 0.7 0.2 0.2 0 0.4 0 0.1-0.1 0.2-0.1 0.1-0.1 0.2-0.2 0-0.1 0-0.3 0-0.1 0-0.2 0 0-0.1-0.1-0.1-0.1-0.3-0.2-0.2-0.1-0.5-0.2-0.5-0.2-0.8-0.5-0.3-0.2-0.3-0.6 0-0.2 0.1-0.4 0.1-0.2 0.3-0.3 0.1-0.2 0.3-0.2 0.2-0.1 0.5-0.1 0.3 0 0.5 0.1 0.2 0 0.4 0.2l0.1-0.3h0.1l0.1 1.1h-0.2q-0.2-0.4-0.4-0.6-0.2-0.2-0.6-0.2-0.3 0-0.4 0.1-0.2 0.2-0.2 0.4 0 0.1 0 0.2 0 0 0.1 0.1 0.1 0.1 0.3 0.2 0.1 0 0.4 0.1 0.3 0.1 0.6 0.3 0.2 0.1 0.3 0.3 0.2 0.1 0.3 0.3 0 0.1 0 0.3 0 0.3-0.1 0.5-0.1 0.2-0.3 0.3-0.1 0.2-0.4 0.2-0.3 0.1-0.6 0.1-0.3 0-0.5-0.1-0.2 0-0.5-0.2l-0.1 0.3h-0.1z"
          />
          <path
            fill="#f2bb0e"
            d="m-61.5 39.4h-0.2q0-0.3-0.1-0.4 0-0.2-0.1-0.3-0.1-0.1-0.3-0.1-0.1 0-0.3 0-0.2 0-0.3 0-0.1 0-0.2 0-0.1 0-0.1 0 0 0.1 0 0.1v2.5q0 0.2 0 0.3 0 0.1 0.1 0.1 0.1 0.1 0.2 0.1 0.1 0.1 0.4 0.1v0.2h-2.1v-0.2q0.3 0 0.4-0.1 0.1 0 0.2-0.1 0.1 0 0.1-0.1 0-0.1 0-0.3v-2.5q0 0 0-0.1 0 0-0.1 0 0 0-0.1 0-0.1 0-0.3 0-0.2 0-0.3 0-0.2 0-0.3 0.1-0.1 0.1-0.2 0.2-0.1 0.2-0.1 0.5h-0.2l0.1-1.1h3.7z"
          />
          <path
            fillRule="evenodd"
            fill="#f2bb0e"
            d="m-58.9 38.3q0.5 0 0.8 0.1 0.4 0.2 0.7 0.4 0.3 0.3 0.4 0.6 0.2 0.4 0.2 0.8 0 0.4-0.2 0.7-0.1 0.3-0.4 0.6-0.3 0.2-0.7 0.4-0.3 0.1-0.8 0.1-0.4 0-0.8-0.1-0.3-0.2-0.6-0.4-0.3-0.3-0.4-0.6-0.2-0.3-0.2-0.7 0-0.4 0.2-0.8 0.1-0.3 0.4-0.6 0.3-0.2 0.6-0.4 0.4-0.1 0.8-0.1zm0 3.4q0.3 0 0.5-0.1 0.3-0.1 0.4-0.3 0.2-0.2 0.3-0.5 0.1-0.3 0.1-0.6 0-0.4-0.1-0.7-0.1-0.3-0.3-0.5-0.1-0.2-0.4-0.3-0.2-0.1-0.5-0.1-0.2 0-0.5 0.1-0.2 0.1-0.3 0.3-0.2 0.2-0.3 0.5-0.1 0.3-0.1 0.7 0 0.3 0.1 0.6 0.1 0.3 0.3 0.5 0.1 0.2 0.3 0.3 0.3 0.1 0.5 0.1z"
          />
          <path
            fill="#f2bb0e"
            d="m-52.9 38.3h0.3l-0.1 1.2h-0.2q-0.1-0.4-0.4-0.7-0.3-0.2-0.7-0.2-0.3 0-0.5 0.1-0.2 0.1-0.4 0.3-0.1 0.2-0.2 0.5-0.1 0.3-0.1 0.7 0 0.7 0.3 1.1 0.3 0.4 0.9 0.4 0.5 0 0.8-0.2 0.3-0.3 0.5-0.8h0.2v1.3h-0.2l-0.1-0.4q-0.5 0.4-1.3 0.4-0.4 0-0.8-0.1-0.3-0.1-0.6-0.4-0.2-0.2-0.4-0.5-0.1-0.4-0.1-0.8 0-0.4 0.1-0.8 0.2-0.3 0.4-0.6 0.3-0.2 0.7-0.4 0.3-0.1 0.8-0.1 0.3 0 0.5 0.1 0.3 0 0.6 0.2z"
          />
          <path
            fill="#f2bb0e"
            d="m-50.6 41.2q0 0.2 0.1 0.3 0 0.1 0 0.2 0.1 0 0.2 0.1 0 0 0.2 0v0.2h-1.7v-0.2q0.2 0 0.3 0 0.1-0.1 0.1-0.1 0.1-0.1 0.1-0.2 0-0.1 0-0.3v-2.1q0-0.2 0-0.3 0-0.1 0-0.2-0.1 0-0.2-0.1-0.1 0-0.2 0v-0.2h1.6v0.2q-0.1 0-0.2 0-0.1 0.1-0.2 0.1 0 0.1 0 0.2-0.1 0.1-0.1 0.3v1l1.3-1.3q0.1-0.1 0.1-0.2 0 0-0.1-0.1 0 0-0.2 0v-0.2h1.3v0.2q-0.1 0-0.2 0-0.1 0-0.2 0.1 0 0-0.1 0.1-0.1 0.1-0.3 0.3l-0.8 0.8 1.4 1.7q0.1 0.2 0.2 0.2 0.1 0.1 0.3 0.1v0.2h-1.8v-0.2q0.2 0 0.3-0.1 0.1 0 0.1 0 0-0.1 0-0.1 0 0 0 0 0-0.1-0.1-0.1 0-0.1-0.1-0.1l-0.8-1.1-0.3 0.3z"
          />
        </g>
        <g></g>
      </g>
      <g id="Logo">
        <g>
          <path
            fill="url(#g1)"
            d="m7.1 22.2c4.5-2.1 19.1-11.3 19.1-11.3l3.2 5.5c0 0-13.1 7.7-19.1 11.3-6.1 3.5-9.2-1.2-9.2-1.2-2.9-3.8 1-9.4 1-9.4-2.4 9.5 5 5.1 5 5.1z"
          />
          <path
            fill="url(#g2)"
            d="m28.7 28.9c-0.3-0.6-0.7-1.7-1.1-2.4l-3.7-6.8c3.1-2 5.5-3.3 5.5-3.3l1.1 1.9c2.6 4.6 1.6 8.9-1.8 10.6z"
          />
          <path
            fill="url(#g3)"
            d="m16.5 9.1c-7-4.9-13.6 13.9-13.6 13.9-2.3-1-0.8-5.9-0.8-5.9l4.8-8c5.5-3.4 9.6 0 9.6 0z"
          />
          <path
            fill="url(#g4)"
            d="m20.8 14.3c0 0-4.8-10.4-13.9-5.2l2.7-4.5c3.7-6.2 9.6-6.1 13.1 0.1l3.5 6.2"
          />
          <path
            fill="url(#g5)"
            d="m19.8 12.5l6.4-1.6-5.4 3.4c0 0-0.2-0.5-1-1.8z"
          />
        </g>
        <g>
          <path
            fill="url(#g6)"
            d="m-78.4 23.6c4.4-2.1 18.6-11 18.6-11l3 5.3c0 0-12.7 7.6-18.5 11-5.9 3.4-8.9-1.1-8.9-1.1-2.9-3.8 0.9-9.2 0.9-9.2-2.3 9.2 4.9 5 4.9 5z"
          />
          <path
            fill="url(#g7)"
            d="m-57.5 30.1c-0.2-0.7-0.6-1.6-1-2.3l-3.6-6.7c3-1.9 5.3-3.2 5.3-3.2l1.1 1.9c2.5 4.4 1.6 8.6-1.8 10.3z"
          />
          <path
            fill="url(#g8)"
            d="m-69.3 10.9c-6.7-4.9-13.2 13.5-13.2 13.5-2.2-1-0.8-5.8-0.8-5.8l4.7-7.8c5.4-3.3 9.3 0.1 9.3 0.1z"
          />
          <path
            fill="url(#g9)"
            d="m-65.1 15.9c0 0-4.7-10.1-13.5-5.1l2.7-4.4c3.5-5.9 9.3-5.8 12.7 0.2l3.4 6"
          />
          <path
            fill="url(#g10)"
            d="m-66.1 14.2l6.3-1.6-5.3 3.3c0 0-0.2-0.5-1-1.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default AppLogo;
