import { observable, action, makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { StatusDateFormat } from '../utils/DateFormat';
import api from './request';

export const TimePeroidTypes = ['1d', '5d', '10d', '20d'];

export const StockTypes = ['KLGD', 'GTGD'];

export interface TCTTChartProps {
  dataPath1: Array<TCTTData>;
  dataPath2: Array<TCTTData>;
  loadData: () => void;
  setTimePeroid: (timePeroid: string) => void;
  setStockType: (stockType: string) => void;
  setDataCode: (dataCode: string) => void;
  setDataTypeCode: (dataCode: string, stockType: string) => void;
  dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
    isIndex?: boolean;
  };
  setDataStockR: (dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
    isIndex?: boolean;
  }) => void;
  dataCode: string;
  timePeroid: string;
  stockType: string;
  loadingPath1: boolean;
  loadingPath2: boolean;
  statusTime?: string;
  date: string;
}

export interface TCTTData {
  x: string;
  y: number;
}

class TCTTChartStore implements TCTTChartProps {
  private apiPath1: string = 'api/stock/volval';
  private apiPath2: string = 'api/stock/volval_rt';

  @observable
  dataPath1: Array<TCTTData> = [];

  @observable
  dataPath2: Array<TCTTData> = [];

  @observable
  timePeroid: string = '20d';

  @observable
  stockType: string = 'GTGD';

  @observable
  dataCode: string = 'vnindex';

  @observable
  dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
  };

  @observable
  loadingPath1: boolean = false;

  @observable
  loadingPath2: boolean = false;

  @observable
  statusTime?: string = '';

  @observable
  date: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setDataStockR = (dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
  }) => {
    runInAction(() => {
      this.dataStockR = dataStockR;
    });
  };

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.timePeroid = timePeroid;
    this.loadData();
  };

  @action
  public setStockType = (stockType: string) => {
    this.stockType = stockType;
    this.loadData();
  };

  @action
  public setDataCode = (dataCode: string) => {
    this.dataCode = dataCode;
    this.dataStockR = undefined;
    this.loadData();
  };

  @action
  public setDataTypeCode = (dataCode: string, stockType: string) => {
    this.stockType = stockType;
    this.dataCode = dataCode;
    this.dataStockR = undefined;
    this.loadData();
  };

  @action
  public loadData = async () => {
    await this.loadDataPath1();
    await this.loadDataPath2();
    runInAction(() => {
      const time = moment().format(StatusDateFormat);
      this.statusTime = time.toString();
    });
  };

  @action
  public loadDataPath1 = async () => {
    this.loadingPath1 = true;
    const type = this.stockType === 'KLGD' ? 'Vol' : 'Val';
    const queryString: string = `${this.apiPath1}?time=${
      this.timePeroid
    }&code=${this.dataCode.toLowerCase()}&type=${type}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataPath1Success)
      .catch(this.fetchDataError);
  };

  @action
  public loadDataPath2 = async (isLoading: boolean = true) => {
    this.loadingPath2 = isLoading;
    const type = this.stockType === 'KLGD' ? 'Vol' : 'Val';
    const queryString: string = `${this.apiPath2}?time=${
      this.timePeroid
    }&code=${this.dataCode.toLowerCase()}&type=${type}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataPath2Success)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataPath2Success = (
    data: Array<{ datetime: string; value: number }>,
  ) => {
    const convertData: Array<TCTTData> = data.map((d) => ({
      x: d.datetime,
      y: d.value,
    }));
    this.dataPath2 = convertData;
    runInAction(() => {
      this.dataPath2 = convertData;
      this.loadingPath2 = false;
      this.date = data[0].datetime.split(' ')[0];
    });
  };

  @action
  private fetchDataPath1Success = (
    data: Array<{ datetime: string; value: number }>,
  ) => {
    const convertData: Array<TCTTData> = data.map((d) => ({
      x: d.datetime,
      y: d.value,
    }));
    runInAction(() => {
      this.dataPath1 = convertData;
      this.loadingPath1 = false;
    });
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loadingPath1 = false;
    this.loadingPath2 = false;
  };
}

export default TCTTChartStore;
