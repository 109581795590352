const AppRecommendationList = (props: any) => {
  const { active } = props;
  return (
    <svg
      width={34}
      height={34}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_142_8101)">
        <path
          d="M17.0553 31.1123C24.1846 31.1123 30.2258 25.7828 31.1081 18.7159C31.1279 18.5544 31.0781 18.3923 30.971 18.2705C30.8633 18.1486 30.7086 18.0789 30.546 18.0789H25.9565C25.6845 18.0789 25.4505 18.2722 25.4001 18.5391C24.6373 22.5409 21.128 25.4456 17.0553 25.4456C12.3684 25.4456 8.55534 21.6325 8.55534 16.9456C8.55534 12.8735 11.4601 9.36417 15.4619 8.60087C15.7293 8.54987 15.922 8.3164 15.922 8.0444V3.45497C15.922 3.29233 15.8523 3.13763 15.7305 3.02997C15.6086 2.9223 15.446 2.87187 15.2851 2.89227C8.2176 3.77457 2.88867 9.8158 2.88867 16.9456C2.88867 24.7571 9.24384 31.1123 17.0553 31.1123ZM14.7887 4.1123V7.58653C10.4928 8.62466 7.42201 12.4871 7.42201 16.945C7.42201 22.257 11.7434 26.5784 17.0553 26.5784C21.5133 26.5784 25.3763 23.5076 26.4138 19.2117H29.8881C28.8057 25.394 23.3986 29.9784 17.0553 29.9784C9.86831 29.9784 4.02201 24.1315 4.02201 16.945C4.02201 10.6023 8.6069 5.1952 14.7887 4.1123Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M18.6486 8.60085C22.0401 9.24741 24.7527 11.9606 25.3993 15.3521C25.4503 15.619 25.6838 15.8122 25.9558 15.8122H30.5452C30.7078 15.8122 30.8625 15.7425 30.9702 15.6207C31.0773 15.4989 31.1272 15.3368 31.1073 15.1753C30.3055 8.74591 25.2548 3.69465 18.8254 2.89281C18.6616 2.87185 18.5018 2.92285 18.38 3.03051C18.2582 3.13818 18.1885 3.29288 18.1885 3.45551V8.04438C18.1885 8.31581 18.3817 8.54985 18.6486 8.60085ZM19.3218 4.11285C24.7193 5.05691 28.9438 9.28141 29.8879 14.6789H26.4131C25.5682 11.2041 22.796 8.43255 19.3212 7.58708L19.3218 4.11285Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M21.3304 12.6695C21.1089 12.448 20.7507 12.448 20.5292 12.6695L12.6701 20.5286C12.4485 20.7502 12.4485 21.1083 12.6701 21.3299C12.7806 21.4404 12.9256 21.4959 13.0707 21.4959C13.2158 21.4959 13.3608 21.4404 13.4713 21.3299L21.3304 13.4708C21.5514 13.2498 21.5514 12.8911 21.3304 12.6695Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M12.6761 12.6763C11.7785 13.5739 11.7785 15.0348 12.6761 15.9324C13.1108 16.367 13.6888 16.6067 14.3036 16.6067C14.9184 16.6067 15.4964 16.3676 15.9316 15.9324C16.8292 15.0348 16.8292 13.5739 15.9316 12.6763C15.4964 12.2411 14.9184 12.002 14.3036 12.002C13.6888 12.002 13.1108 12.2417 12.6761 12.6763ZM15.1304 15.1311C14.6889 15.572 13.9188 15.5726 13.4774 15.1311C13.0212 14.6755 13.0212 13.9338 13.4774 13.4782C13.6984 13.2572 13.9914 13.1359 14.3036 13.1359C14.6158 13.1359 14.9094 13.2577 15.1304 13.4782C15.586 13.9338 15.586 14.675 15.1304 15.1311Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M19.6952 21.9969C20.2851 21.9969 20.8744 21.7725 21.3232 21.3237C22.2208 20.4261 22.2208 18.9652 21.3232 18.0676C20.4245 17.1694 18.9642 17.1706 18.0677 18.0676C17.1701 18.9652 17.1701 20.4261 18.0677 21.3237C18.5165 21.7725 19.1059 21.9969 19.6952 21.9969ZM18.869 18.8694C19.0968 18.6416 19.3966 18.5277 19.6952 18.5277C19.9944 18.5277 20.2942 18.6416 20.522 18.8694C20.9781 19.325 20.9781 20.0668 20.522 20.5224C20.0658 20.9774 19.3252 20.9786 18.869 20.5224C18.4128 20.0668 18.4128 19.325 18.869 18.8694Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_142_8101">
          <rect width={34} height={34} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppRecommendationList;
