export const AppBaseUrl = process.env.REACT_APP_API;
export const AppBaseUrlWS = process.env.REACT_APP_API_WS;
export const IntervalMarketChart = 300000;
export const IntervalPTTBChart = 900000;
export const IntervalDTCTTChart = 60000;
export const IntervalPTTBChartList = 10000;
export const IntervalKhoiNgoai = 300000;
export const IntervalKhoiNgoai2 = 300000;
export const IntervalVolatility = 60000;
export const IntervalTopCoPhieu = 120000;
export const IntervalRecom = 60000;
export const IntervalTradingSignal = 20000;
export const TimeOutActiveNewTab = 10000;
export const drawerWidth: number = 180;
export const AppGuideUrl = process.env.REACT_APP_GUIDE_URL;
export const routes = [
  {
    id: 1,
    title: 'Home',
    slug: 'home',
    module: 'Dashboard',
  },
  {
    id: 2,
    title: 'Setting',
    slug: 'setting',
    module: 'SettingPage',
  },
  {
    id: 3,
    title: 'RRG',
    slug: 'relative-rotation-graphs',
    module: 'RRGPage',
  },
  {
    id: 4,
    title: 'Login',
    slug: 'login',
    module: 'LoginPage',
  },
  {
    id: 5,
    title: 'Signup',
    slug: 'signup',
    module: 'SignupPage',
  },
  {
    id: 6,
    title: 'LogOut',
    slug: 'logout',
    module: 'LogOutPage',
  },
  {
    id: 7,
    title: 'Forgot Password',
    slug: 'forgot',
    module: 'ForgotPage',
  },
  {
    id: 8,
    title: 'Change Password',
    slug: 'reset',
    module: 'ChangePasswordPage',
  },
  {
    id: 9,
    title: 'Welcome',
    slug: 'active',
    module: 'ActivePage',
  },
  {
    id: 10,
    title: 'Investment Strategy',
    slug: 'investment-strategy',
    module: 'InvestmentStrategyPage',
  },
  {
    id: 11,
    title: 'Reporting And Analysis',
    slug: 'reporting-analysis',
    module: 'ReportingAnalysisPage',
  },
  {
    id: 12,
    title: 'User Info',
    slug: 'user-info',
    module: 'UserInfoPage',
  },
  {
    id: 13,
    title: 'Trading Signal',
    slug: 'trading-signal',
    module: 'TradingSignalPage',
  },
  {
    id: 14,
    title: 'Recommendation List',
    slug: 'idea-trading',
    module: 'RecommendationListPage',
  },
  {
    id: 15,
    title: 'Top Stock',
    slug: 'top-stock',
    module: 'TopStockPage',
  },
  {
    id: 16,
    title: 'Industry Developments',
    slug: 'industry-developments',
    module: 'IndustryDevelopmentsPage',
  },
  {
    id: 20,
    title: 'Industry Developments',
    slug: 'industry-developments-stock',
    module: 'IndustryDevelopmentsPage',
  },
  {
    id: 17,
    title: 'Industry Volatility',
    slug: 'industry-volatility',
    module: 'IndustryVolatilityPage',
  },
  {
    id: 18,
    title: 'Industry Stock',
    slug: 'industry-stock',
    module: 'IndustryVolatilityPage',
  },
  {
    id: 19,
    title: 'Correlation Graph',
    slug: 'stock360',
    module: 'CorrelationGraphPage',
  },
  {
    id: 20,
    title: 'Nâng Cấp',
    slug: 'upgrade',
    module: 'SubscribePage',
  },
];
