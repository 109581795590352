import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface AppToggleTimePeriodProps {
  value?: string;
  types: any;
  onChange?: (newValue: string) => any;
  width?: number;
  height?: number;
}

export const AppToggleTimePeriod: React.FC<AppToggleTimePeriodProps> = ({
  value,
  onChange,
  types,
  width,
  height,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    onChange && newValue && newValue !== value && onChange(newValue);
  };
  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
    >
      {types.map((value: string) => (
        <ToggleButton
          key={value}
          value={value}
          sx={{
            height: height ? height : 24,
            width: width ? width : 34,
            fontSize: 11,
            fontWeight: 400,
            backgroundColor: 'unset!important',
          }}
        >
          {value}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
