import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';
import moment from 'moment';

export interface TrendingProps {
  data: TrendingData;
}

export interface TrendingData {
  ngan_han: 0 | 1;
  trung_han: 0 | 1;
  dai_han: 0 | 1;
}

class TrendingStore implements TrendingProps {
  private apiPath: string = 'charts_json/rt/trend';

  @observable
  data: TrendingData = { ngan_han: 0, trung_han: 0, dai_han: 0 };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadData = () => {
    const day = moment().date().toString();
    const queryString: string = `${this.apiPath}.json?day=${day}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: TrendingData) => {
    this.data = data;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
  };
}

export default new TrendingStore();
