import { toast } from 'react-toastify';

export interface NotificationProps {
  showSuccessMessage: (message: string) => void;
  showErrorMessage: (message: string) => void;
  showWarningMessage: (message: string) => void;
  showInfoMessage: (message: string) => void;
  clearMessage: () => void;
}

class NotificationStore {
  showSuccessMessage = (message: string) => {
    toast.success(message, {
      toastId: 'showSuccessMessage',
    });
  };

  showErrorMessage = (message: string) => {
    toast.error(message, {
      toastId: 'showErrorMessage',
    });
  };

  showWarningMessage = (message: string) => {
    toast.warn(message, {
      toastId: 'showWarningMessage',
    });
  };

  showInfoMessage = (message: string) => {
    toast.info(message, {
      toastId: 'showInfoMessage',
    });
  };

  clearMessage = () => {
    console.log('clear');
    toast.dismiss();
  };
}

export default new NotificationStore();
