import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';

export interface CG2TransInfoStoreProps {
  data: Array<GCMarketData>;
  loadData: () => void;
  loading: boolean;
  selectedMarket: GCMarketData;
  setSelectedMarket: (selectedMarket: GCMarketData) => void;
}

export interface GCMarketData {
  market: string;
  data: {
    sectorId: number;
    code: string;
    adClose: number;
    pctChange: number;
    nmVolume: number;
  }[];
}

class CG2TransInfoStore implements CG2TransInfoStoreProps {
  private apiPath: string = 'api/company/info';

  @observable
  data: Array<GCMarketData> = [];

  @observable
  loading: boolean = true;

  @observable
  selectedMarket: GCMarketData = {
    market: '',
    data: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
    if (this.data && this.data.length > 0) this.selectedMarket = this.data[0];
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public setSelectedMarket = (selectedMarket: GCMarketData) => {
    this.selectedMarket = selectedMarket;
  };
}

export default new CG2TransInfoStore();
