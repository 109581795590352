import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import { configColors } from '../../const/colors';

LoadingOverlay.propTypes = undefined;

export const AppBlockLoading: React.FC<{
  loading?: boolean;
  height?: string;
  children: React.ReactNode;
}> = ({ loading, children, height }) => (
  <LoadingOverlay
    active={loading}
    spinner={<ClipLoader color={configColors.loading.spinner} />}
    styles={{
      overlay: (base: any) => ({
        ...base,
        background: configColors.loading.overlay,
      }),
      wrapper: (base: any) => ({
        ...base,
        width: '100%',
        height: height,
      }),
    }}
  >
    {children}
  </LoadingOverlay>
);
