import { observable, action, makeAutoObservable } from 'mobx';
import api from '../../request';
import { FIFilter } from './FinancialIndicatorsStore';

export interface BaoCaoTaiChinhStoreProps {
  data: {
    code: string;
    name: string;
    data: Array<BaoCaoTaiChinhData>;
    label: Array<string>;
  };
  code: string;
  type: string;
  donvi: string;
  tabValue: number;
  setTabValue: (tabValue: number) => void;
  setDonvi: (donvi: string) => void;
  loadData: () => void;
  setCode: (code: string, loadData?: boolean) => void;
  loading: boolean;
  ifFilter: FIFilter;
  setFIFilter: (ifFilter: string, value: number) => void;
  ReportingYearPeriod: string[];
}

export interface BaoCaoTaiChinhData {
  itemName: string;
  itemCode: string;
  displayLevel: number;
  bkIndex: number;
  data: Array<{
    itemCode: string;
    numericValue: number;
    fiscalDate: string;
  }>;
}

class BaoCaoTaiChinhStore implements BaoCaoTaiChinhStoreProps {
  private apiPath: string = 'api/company/finance_statements';

  @observable
  loading: boolean = true;

  @observable
  data: {
    code: string;
    name: string;
    data: Array<BaoCaoTaiChinhData>;
    label: Array<string>;
    unit?: String;
  } = {
    code: '',
    name: '',
    data: [],
    label: [],
  };

  @observable
  ifFilter: FIFilter = {
    timePeroidTypes: 0,
    reportingPeriod: 1,
    reportingYearPeriod: 0,
    displayPeriods: 5,
    currencyPeroidTypes: 0,
  };

  @observable
  ReportingYearPeriod: string[] = [];

  @observable
  tabValue: number = 0;

  @observable
  code: string = 'MBS';

  @observable
  type: string = '89'; // 89,90,91

  @observable
  donvi: string = 'Tỷ đồng'; // 89,90,91

  constructor() {
    makeAutoObservable(this);
    //Fill Year
    let currentYear = new Date().getFullYear();
    while (currentYear >= 2011) {
      this.ReportingYearPeriod.push(currentYear.toString());
      currentYear = currentYear - 1;
    }

    let currentQ = Math.floor((new Date().getMonth() + 3) / 3);
    currentYear = new Date().getFullYear();

    if (currentQ === 1) {
      currentQ = 4;
      currentYear = currentYear - 1;
    } else currentQ = currentQ - 1;

    this.ifFilter.reportingPeriod = currentQ;
    this.ifFilter.reportingYearPeriod = this.ReportingYearPeriod.indexOf(
      currentYear.toString(),
    );
  }

  @action
  public setTabValue = (tabValue: number) => {
    this.tabValue = tabValue;
    if (tabValue === 0) {
      this.type = '89';
    }
    if (tabValue === 1) {
      this.type = '90';
    }
    if (tabValue === 2) {
      this.type = '91';
    }
    if (tabValue === 3) {
      this.type = '92';
    }
    this.loadData();
  };

  @action
  public setFIFilter = (ifFilter: string, value: number) => {
    if (ifFilter === 'currencyPeroidTypes')
      this.ifFilter = { ...this.ifFilter, currencyPeroidTypes: value };
    else if (ifFilter === 'displayPeriods')
      this.ifFilter = { ...this.ifFilter, displayPeriods: value };
    else if (ifFilter === 'reportingPeriod')
      this.ifFilter = { ...this.ifFilter, reportingPeriod: value };
    else if (ifFilter === 'reportingYearPeriod')
      this.ifFilter = { ...this.ifFilter, reportingYearPeriod: value };
    else if (ifFilter === 'timePeroidTypes') {
      this.ifFilter = { ...this.ifFilter, timePeroidTypes: value };
    }
    this.loadData();
  };

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadData();
  };

  @action
  public setDonvi = (donvi: string) => {
    this.donvi = donvi;
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}?code=${this.code}&type=${
      this.type
    }&period=${this.ifFilter.reportingPeriod}&year=${
      this.ReportingYearPeriod[this.ifFilter.reportingYearPeriod]
    }&period_num=${this.ifFilter.displayPeriods}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default BaoCaoTaiChinhStore;
