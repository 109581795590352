import Box from '@mui/material/Box';

const icon: { [key: string]: string } = {
  star_0: 'star_0.svg',
  star_1: 'star_1.svg',
};

export const AppIcon: React.FC<{ ico: string }> = ({ ico }) => (
  <Box
    sx={{
      height: 11,
      width: 11,
      marginRight: '6px',
      backgroundImage: 'url(../assets/images/icon)'.replace('icon', icon[ico]),
    }}
  />
);
