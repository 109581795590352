import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export interface IndustryVolatilityStoreProps {
  loadData: (isLoading?: boolean) => void;
  loading: boolean;
  filters: Filters;
  data: Array<IData>;
  timePeroid: '1 Ngày' | '1 Tuần' | '1 Tháng';
  sectorID?: string;
  updateSectorID: (sectorID?: string) => void;
  updateTimePeroid: (timePeroid: '1 Ngày' | '1 Tuần' | '1 Tháng') => void;
  updateFilters: (filters: Filters) => void;
}

export interface Floor {
  id: number;
  name: string;
}

export interface IData {
  adClose: number;
  code: string;
  name: number;
  nmVolume: number;
  volMa20: number;
  pctChange: number;
  totalVal: number;
  totalVol: number;
  sectorId?: number;
  totalDecrease?: number;
  totalIncrease?: number;
}

export interface Filters {
  floors: Array<Floor>;
  min_adClose?: number;
  max_adClose?: number;
  min_MA20?: number;
  max_MA20?: number;
}

export const FloorTypes: Array<Floor> = [
  { id: 1, name: 'hnx' },
  { id: 2, name: 'hose' },
  { id: 3, name: 'upcom' },
];

class IndustryVolatilityStore implements IndustryVolatilityStoreProps {
  private apiPath: string = 'api/volatility';

  @observable
  data: Array<IData> = [];

  @observable
  sectorID?: string;

  @observable
  timePeroid: '1 Ngày' | '1 Tuần' | '1 Tháng' = '1 Ngày';

  @observable
  loading: boolean = false;

  @observable
  filters: Filters = {
    floors: FloorTypes,
    min_adClose: 10,
    max_adClose: 500,
    min_MA20: 100,
    max_MA20: 200000,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  updateSectorID = (sectorID?: string) => (this.sectorID = sectorID);

  @action
  public updateTimePeroid = (timePeroid: '1 Ngày' | '1 Tuần' | '1 Tháng') => {
    this.timePeroid = timePeroid;
    this.loadData();
  };

  @action
  public updateFilters = (filters: Filters) => {
    this.filters = filters;
    this.loadData();
  };

  @action
  public loadData = (isLoading: boolean = true) => {
    this.loading = isLoading;
    const { floors, min_adClose, max_adClose, min_MA20, max_MA20 } =
      this.filters;
    let queryString: string = `${this.apiPath}`;
    let day = 1;
    if (this.timePeroid === '1 Tuần') day = 2;
    if (this.timePeroid === '1 Tháng') day = 3;
    if (this.sectorID) {
      let flsn: string[] = [];
      flsn = floors.slice().map((f) => f.name);
      let minmaxCLose = [0, 500];
      let minmaxMA20 = [0, 200000];
      if (min_adClose !== undefined && max_adClose !== undefined) {
        minmaxCLose = [min_adClose, max_adClose];
      }
      if (min_MA20 !== undefined && max_MA20 !== undefined) {
        minmaxMA20 = [min_MA20, max_MA20];
      }
      queryString = `${this.apiPath}?day=${day}&sectorId=${
        this.sectorID
      }&floor=${flsn.join(',')}&min_adClose=${minmaxCLose[0]}&max_adClose=${
        minmaxCLose[1]
      }&min_MA20=${minmaxMA20[0]}&max_MA20=${minmaxMA20[1]}`;
    } else {
      queryString = `${this.apiPath}?day=${day}`;
    }
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: any) => {
    switch (this.timePeroid) {
      case '1 Tuần':
        this.data = data.map((k: any) => ({
          ...k,
          volMa20: k.volMa20w,
          nmVolume: k.nmVolumew,
          pctChange: k.pctChangew,
        }));
        break;
      case '1 Tháng':
        this.data = data.map((k: any) => ({
          ...k,
          volMa20: k.volMa20m,
          nmVolume: k.nmVolumem,
          pctChange: k.pctChangem,
        }));
        break;
      default:
        this.data = data;
        break;
    }
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    this.loading = false;
  };
}

export default new IndustryVolatilityStore();
