const AppIndustryDevelopIcon = (props: any) => {
  const { active } = props;
  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2687_5410)">
        <path
          d="M24.574 23.2449C22.3827 23.2449 20.2305 23.2449 18.0392 23.2449C14.5957 23.2449 11.1522 23.2449 7.66963 23.2449C6.37833 23.2449 5.16529 23.3229 3.99137 22.6209C2.38702 21.6849 1.72181 20.0858 1.72181 18.3307C1.72181 15.0546 1.72181 11.7395 1.72181 8.46335C1.72181 6.08426 1.72181 3.70516 1.72181 1.32607C1.72181 1.17006 1.72181 0.975054 1.72181 0.819048C1.72181 -0.311997 -0.0390625 -0.311997 -0.0390625 0.819048C-0.0390625 3.04214 -0.0390625 5.26523 -0.0390625 7.48832C6.79307e-05 10.9985 6.79307e-05 14.4306 6.79307e-05 17.9017C6.79307e-05 18.1747 6.79307e-05 18.4478 6.79307e-05 18.7598C6.79307e-05 20.2028 0.508763 21.6069 1.40876 22.7379C2.70007 24.376 4.65659 25.039 6.69137 25.039C10.0566 25.039 13.3827 25.039 16.7479 25.039C19.174 25.039 21.6392 25.039 24.0653 25.039C24.2218 25.039 24.3783 25.039 24.5348 25.039C25.6696 25 25.7088 23.2449 24.574 23.2449Z"
          fill={active ? '#F2BB0E' : 'white'}
        ></path>
        <path
          d="M2.46526 19.8908C5.087 17.2777 7.70874 14.7426 10.2913 12.0905C10.4087 11.9735 10.0957 12.2075 10.3305 12.0515C10.5261 11.9345 10.7218 11.8175 10.9566 11.9735C11.4261 12.2855 11.8566 12.8705 12.2479 13.2605C13.4609 14.4696 14.674 15.7176 15.887 16.9267C16.6305 17.6677 17.4913 18.1747 18.587 18.1747C19.6044 18.1747 20.5826 17.7067 21.2479 16.9267C21.6783 16.4197 22.0696 15.8736 22.5 15.3666C23.9087 13.6116 25.3174 11.8565 26.7261 10.1014C27.0392 9.7504 27.0783 9.20438 26.7261 8.85336C26.4131 8.54135 25.787 8.46335 25.474 8.85336C24.2609 10.3744 23.0479 11.8565 21.8348 13.3775C21.2479 14.0796 20.6609 14.8206 20.1131 15.5226C19.8392 15.8736 19.5261 16.1857 19.0957 16.3027C18.2348 16.5757 17.6087 16.0687 17.0218 15.4836C15.8087 14.2746 14.5957 13.0265 13.3827 11.8175C12.9522 11.3495 12.4827 10.8424 11.974 10.4524C11.1913 9.9064 10.1348 9.98441 9.35222 10.5304C8.92178 10.8424 8.53048 11.2715 8.1783 11.6615C7.35656 12.4805 6.53483 13.2995 5.71309 14.1186C4.22613 15.6786 2.73917 17.1607 1.25222 18.6428C0.430479 19.4618 1.68265 20.6708 2.46526 19.8908Z"
          fill={active ? '#F2BB0E' : 'white'}
        ></path>
        <path
          d="M2.07404 11.1544C3.91317 8.93136 5.7523 6.74727 7.59143 4.52418C8.02187 4.01716 8.4523 3.47114 8.92187 2.96412C9.07839 2.76911 9.3523 2.30109 9.62622 2.22309C9.74361 2.18409 10.0567 2.30109 9.90013 2.22309C9.93926 2.26209 9.97839 2.26209 9.97839 2.30109C10.174 2.4181 10.3697 2.5741 10.5653 2.69111C11.1914 3.19813 11.8567 3.70515 12.4827 4.17317C13.6958 5.1092 14.9088 6.04524 16.1219 6.98128C16.474 7.25429 17.0219 7.37129 17.374 6.98128C18.3523 5.92824 19.3306 4.87519 20.3088 3.82215C20.7393 3.35413 21.1697 2.88611 21.6001 2.4571C21.7958 2.22309 22.0697 2.22309 22.2262 2.30109C22.148 2.26209 22.3436 2.37909 22.3827 2.4181C22.5393 2.5351 22.6958 2.65211 22.8132 2.76911C23.4393 3.27613 24.1045 3.78315 24.7306 4.25117C25.1219 4.52418 25.5914 4.60218 25.9827 4.25117C26.2958 3.93916 26.374 3.31513 25.9827 3.00312C25.3567 2.5351 24.7697 2.06708 24.1436 1.59906C23.9088 1.40406 23.674 1.20905 23.4393 1.05304C22.8132 0.546021 21.9914 0.351013 21.2088 0.663025C20.5045 0.936036 20.0349 1.56006 19.5653 2.06708C18.9001 2.80811 18.1958 3.54914 17.5306 4.25117C17.061 4.75819 16.5914 5.26521 16.1219 5.77223C16.5523 5.77223 16.9436 5.77223 17.374 5.77223C16.3175 4.9532 15.2219 4.09516 14.1653 3.27613C13.461 2.73011 12.7567 2.18409 12.0132 1.63806C11.6219 1.32605 11.1914 0.936036 10.761 0.702027C9.82187 0.234008 8.72622 0.507019 8.061 1.28705C7.70883 1.67707 7.35665 2.10608 7.04361 2.5351C5.83057 3.97816 4.61752 5.42122 3.40448 6.86427C2.54361 7.87831 1.72187 8.89235 0.861 9.9064C0.547957 10.2574 0.508826 10.8034 0.861 11.1544C1.17404 11.4665 1.80013 11.5055 2.07404 11.1544Z"
          fill={active ? '#F2BB0E' : 'white'}
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2687_5410">
          <rect
            width="27"
            height="25"
            fill={active ? '#F2BB0E' : 'white'}
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppIndustryDevelopIcon;
