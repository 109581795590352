import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';
import moment from 'moment';
import { toSlug } from '../utils/StringFormat';
import { StatusDateFormat } from '../utils/DateFormat';
import { MarketIndexProps } from './marketIndexStore';

export const TimePeroidTypes = ['1m', '3m', '6m', '1y', '3y', '5y'];

export interface MarketChartProps {
  data: Array<MarketData>;
  loadData: () => void;
  setTimePeroid: (timePeroid: string) => void;
  setDataCode: (dataCode: string) => void;
  setDataEndNode: (endNote: MarketData) => void;
  dataCode: string;
  timePeroid: string;
  statusTime?: string;
  marketIndexStore: MarketIndexProps;
}

export interface MarketData {
  x: string;
  y: number;
}

class MarketChartStore implements MarketChartProps {
  private apiPath: string = 'charts_json';

  marketIndexStore: MarketIndexProps;

  @observable
  data: Array<MarketData> = [];

  @observable
  timePeroid: string = '1y';

  @observable
  dataCode: string = 'vnindex';

  @observable
  statusTime?: string = '';

  constructor(marketIndexStore: MarketIndexProps) {
    this.marketIndexStore = marketIndexStore;
    makeAutoObservable(this);
  }

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.timePeroid = timePeroid;
    this.loadData();
  };

  @action
  public setDataCode = (dataCode: string) => {
    this.dataCode = dataCode;
    this.loadData();
  };

  @action
  public setDataEndNode = (endNote: MarketData) => {
    if (this.data.length > 0) {
      if (
        this.data[this.data.length - 1].x === endNote.x &&
        this.data[this.data.length - 1].y !== endNote.y &&
        !!endNote.x
      ) {
        const tmpData = this.data.filter((x) => x.x !== endNote.x);
        this.data = [...tmpData, endNote];
      } else if (
        this.data[this.data.length - 1].x !== endNote.x &&
        this.data[this.data.length - 1].y !== endNote.y &&
        !!endNote.x
      ) {
        if (
          moment(this.data[this.data.length - 1].x).isBefore(moment(endNote.x))
        )
          this.data = [...this.data, endNote];
      }
    }
  };

  @action
  public loadData = async () => {
    const day = moment().milliseconds().toString();
    let code = this.dataCode;
    if (this.dataCode === 'MidCap') {
      code = 'VNMID';
    }
    if (this.dataCode === 'SmallCap') {
      code = 'VNSML';
    }
    const queryString: string = `${this.apiPath}/${toSlug(code)}_${
      this.timePeroid
    }.json?day=${day}`;
    api
      .get(queryString)
      .then((resp) => resp.data.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (
    datares: Array<{ date: string; value: number }>,
  ) => {
    const convertData: Array<MarketData> = datares.map((d) => ({
      x: d.date,
      y: d.value,
    }));
    this.data = convertData;
    this.marketIndexStore.loadData((endNodeData: any) => {
      const endNode = endNodeData.find(
        (d: any) => d.code.toLowerCase() === this.dataCode.toLowerCase(),
      );
      endNode && this.setDataEndNode({ x: endNode.date, y: endNode.value });
    });
    const time = moment().format(StatusDateFormat);
    this.statusTime = time.toString();
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
  };
}

export default MarketChartStore;
