import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export interface CanhBaoProps {
  data: Array<CanhBaoData>;
  loadData: () => void;
  loading: boolean;
  statusTime?: string;
  filters: Filters;
  updateFilters: (filters: Filters) => void;
}

export interface CanhBaoData {
  code: string;
  title: string;
  message: string;
  exp: number;
  type: number;
  date: string;
}

export interface Floor {
  id: number;
  name: string;
}

export interface Filters {
  floors: Array<Floor>;
  min_adClose?: number;
  max_adClose?: number;
  min_MA20?: number;
  max_MA20?: number;
}

export const FloorTypes: Array<Floor> = [
  { id: 1, name: 'hnx' },
  { id: 2, name: 'hose' },
  { id: 3, name: 'upcom' },
];

class CanhBaoStore implements CanhBaoProps {
  private apiPath: string = 'api/alerts';

  @observable
  data: Array<CanhBaoData> = [];

  @observable
  loading: boolean = false;

  @observable
  statusTime?: string = '';

  @observable
  filters: Filters = {
    floors: FloorTypes,
    min_adClose: 10,
    max_adClose: 500,
    min_MA20: 100,
    max_MA20: 200000,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public updateFilters = async (filters: Filters) => {
    this.filters = filters;
    this.loadData();
  };

  @action
  public loadData = () => {
    const { floors, min_adClose, max_adClose, min_MA20, max_MA20 } =
      this.filters;
    let flsn: string[] = [];
    flsn = floors.slice().map((f) => f.name);
    let minmaxCLose = [0, 500];
    let minmaxMA20 = [0, 200000];
    if (min_adClose !== undefined && max_adClose !== undefined) {
      minmaxCLose = [min_adClose, max_adClose];
    }
    if (min_MA20 !== undefined && max_MA20 !== undefined) {
      minmaxMA20 = [min_MA20, max_MA20];
    }
    const queryString: string = `${this.apiPath}?floor=${flsn.join(
      ',',
    )}&min_adClose=${minmaxCLose[0]}&max_adClose=${minmaxCLose[1]}&min_MA20=${
      minmaxMA20[0]
    }&max_MA20=${minmaxMA20[1]}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
    this.loading = true;
  };

  @action
  private fetchDataSuccess = (data: Array<CanhBaoData>) => {
    this.data = data;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    this.loading = false;
  };
}

export default new CanhBaoStore();
