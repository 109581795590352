import moment from 'moment';

export const DateFormat = 'DD/MM/YYYY';
export const DateChartFormat = 'DD/MM/YY';
export const FormatDDMM = 'DD/MM';
export const DateChartTickFormatMonth = 'MM/YY';
export const DateChartTickFormatMMYYHH = 'HH:mm';
export const DateFormatDDMMHH = 'DD/MM HH:mm';

export const ISODateFormat = 'YYYY-MM-DD';
export const StatusDateFormat = 'DD/MM/YY H:mm:ss';

export const formatDateFormatDDMMHH = (date?: string) => {
  if (!date) return moment().format(DateFormatDDMMHH).toString();
  return moment(date).format(DateFormatDDMMHH).toString();
};

export const formatFormatMMYYHH = (date?: string) => {
  if (!date) return moment().format(DateChartTickFormatMMYYHH).toString();
  return moment(date).format(DateChartTickFormatMMYYHH).toString();
};

export const formatDDMMYYYY = (date?: string) => {
  if (!date) return moment().format(DateFormat).toString();
  return moment(date).format(DateFormat).toString();
};

export const formatDDMMYY = (date?: string) => {
  if (!date) return moment().format(DateChartFormat).toString();
  return moment(date).format(DateChartFormat).toString();
};

export const formatMMYY = (date?: string) => {
  if (!date) return moment().format(DateChartTickFormatMonth).toString();
  return moment(date).format(DateChartTickFormatMonth).toString();
};

export const formatDDMM = (date?: string) => {
  if (!date) return moment().format(FormatDDMM).toString();
  return moment(date).format(FormatDDMM).toString();
};

export const getQuyOrname = (
  date: string,
  isQuy: boolean = false,
  formatYear: boolean = true,
) => {
  const dateT = moment(date);
  if (isQuy) {
    return formatYear
      ? `Q${Math.ceil(dateT.month() / 3)}'${dateT
          .year()
          .toString()
          .slice(2, 4)}`
      : `Q${Math.ceil(dateT.month() / 3)} - ${dateT.year().toString()}`;
  }
  return `${dateT.year()}`;
};
