import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AppBlockLoading } from './AppBlockLoading';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export const AppFormDialog: React.FC<{
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  removeTaiChinhValue: () => void;
  title: string;
  children?: React.ReactNode;
  onSubmit?: () => void;
  isUpdate: boolean;
}> = ({
  open,
  handleClose,
  title,
  loading,
  onSubmit,
  removeTaiChinhValue,
  children,
  isUpdate,
}) => {
  return (
    <Dialog disableEscapeKeyDown open={open}>
      <Box
        sx={{
          height: '40px',
          backgroundColor: '#2A334C',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography fontSize={12} fontWeight={700} pl="10px">
          {title}
        </Typography>
      </Box>
      <DialogContent
        sx={{
          width: '322px',
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          height: 200,
          justifyContent: 'space-between',
        }}
      >
        {children}
        <Box>
          <AppBlockLoading loading={loading}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isUpdate ? 'space-between' : 'flex-end',
                width: '100%',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
            >
              {isUpdate && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={removeTaiChinhValue}
                  sx={{ marginRight: 1, fontSize: 13, textTransform: 'none' }}
                >
                  Xóa biểu đồ
                </Button>
              )}
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  sx={{ marginRight: 1, fontSize: 13, textTransform: 'none' }}
                >
                  Hủy
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  sx={{ fontSize: 13, textTransform: 'none' }}
                >
                  {isUpdate ? 'Lưu' : 'Thêm'}
                </Button>
              </Box>
            </Box>
          </AppBlockLoading>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
