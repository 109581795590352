import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export const TimePeroidTypes = ['1d', '5d', '10d', '20d'];

export const StockTypes = ['KLGD', 'GTGD'];

export interface RGGChartProps {
  data: Array<TCTTData>;
  marketData: Array<TCTTData>;
  dataVNIndex: Array<VNIndexData>;
  dataMiniChart: {
    code?: string;
    node?: any;
    data: Array<VNIndexData>;
  };
  loadData: () => void;
  loadDataMiniChart: (node: TCTTData) => void;
  loading: boolean;
  isFit: boolean;
  minmaxIndex: number[];
  minmaxBKIndex: number[];
  minmaxX: number[];
  minmaxY: number[];
  updateMinMaxIndex: (minmaxIndex: number[], isPlay?: boolean) => void;
  setSectorID: (sectorID: number) => void;
  updateSelected: (lineID: string, selected: boolean) => void;
  loadDataByStockCode: (code: string) => void;
  setWeek: (week: number) => void;
  setFit: (isFit: boolean) => void;
  updateData: (data: any) => void;
  selectedSector: string;
  setSelectedSector: (data: any) => void;
}

export interface TCTTData {
  selected: boolean;
  code: string;
  name: string;
  type: number;
  chart: {
    index: number;
    y: number;
    x: number;
    isFirst: boolean;
    DT: string;
    adChange: number;
    adClose: number;
    nmVolume: number;
    pctChange: number;
    volMa20: number;
  }[];
}
export interface VNIndexData {
  x: string;
  y: number;
  index: number;
  adClose?: number;
}

class RGGChartStore implements RGGChartProps {
  private apiPath: string = 'api/rrg';
  private apiPathVnindex: string = 'api/rrg/vnindex';
  private apiPathSector: string = 'api/rrg/sector?code=';
  private apiPathStock: string = 'api/rrg/stock?code=';

  @observable
  data: Array<TCTTData> = [];

  marketData: Array<TCTTData> = [];
  savedCode: string = '';
  savedMiniChart: TCTTData = {
    selected: false,
    name: '',
    code: '',
    type: 0,
    chart: [],
  };

  @observable
  minmaxX: number[] = [90, 110];

  @observable
  minmaxY: number[] = [90, 110];

  @observable
  minmaxIndex: number[] = [41, 49];

  @observable
  minmaxBKIndex: number[] = [41, 49];

  @observable
  sectorParam: string = '';

  @observable
  week: number = 0;

  @observable
  dataVNIndex: Array<VNIndexData> = [];

  @observable
  dataMiniChart: {
    code?: string;
    node?: any;
    data: Array<VNIndexData>;
  } = {
    data: [],
  };

  @observable
  isFit: boolean = true;

  @observable
  loading: boolean = false;

  @observable
  selectedSector: string = '0';

  keepSelected: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setFit = (isFit: boolean) => {
    this.isFit = isFit;
    this.updateMinMax();
  };

  @action
  public updateMinMaxIndex = (minmaxIndex: number[], isPlay?: boolean) => {
    this.minmaxIndex = minmaxIndex;
    if (!isPlay) {
      this.minmaxBKIndex = minmaxIndex;
    }
  };

  @action
  public setSectorID = (sectorID: number) => {
    this.sectorParam = sectorID > 0 ? 'sectorId=' + sectorID : '';
    this.loadData();
  };

  @action
  public setSelectedSector = (sectorID: any) => {
    this.selectedSector = sectorID;
  };

  @action
  public setWeek = (week: number) => {
    this.week = week;
    this.keepSelected = true;
    if (this.savedCode !== '') this.loadDataByStockCode(this.savedCode);
    else this.loadData();
    if (this.savedMiniChart.code !== '')
      this.loadDataMiniChart(this.savedMiniChart);
    this.loadDataVNIndex();
  };

  @action
  public updateSelected = (lineID: string, selected: boolean) => {
    let selectedItem = this.data.find((d: any) => d.code === lineID);
    if (selectedItem) selectedItem.selected = selected;
    this.updateMinMax();
  };

  @action
  public updateData = (data: any[]) => {
    this.data = data;
  };

  @action
  public loadData = async () => {
    this.savedCode = '';
    this.clearMiniChartData();
    this.loading = true;
    const queryString: string = `${this.apiPath}?week=${this.week}&${this.sectorParam}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadDataVNIndex = async () => {
    const queryString: string = `${this.apiPathVnindex}?week=${this.week}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataVNIndexSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadDataMiniChart = async (node: TCTTData) => {
    this.savedMiniChart = node;
    this.loading = true;
    const queryString: string =
      node.type === 1
        ? `${this.apiPathSector}${node.code}&week=${this.week}`
        : `${this.apiPathStock}${node.code}&week=${this.week}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then((res) => this.fetchDataMiniChartSuccess(res, node))
      .catch(this.fetchDataError);
  };

  @action
  public loadDataByStockCode = async (code: string) => {
    if (code === '') {
      this.clearData();
      return;
    }
    this.savedCode = code;
    this.loading = true;
    const queryString: string = `${this.apiPath}?week=${this.week}&codes=${code}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
    this.clearMiniChartData();
  };

  @action
  private updateMinMax = () => {
    const dataChart = this.data.slice().filter((t) => t.selected);
    if (dataChart.length > 0) {
      let minmaxX = [0, 1];
      let minmaxY = [0, 1];
      let tmpData: any = [];
      const minmaxIndex = this.minmaxIndex.slice();
      for (let index = 0; index < dataChart.length; index++) {
        const element = dataChart[index];
        element.chart
          .slice(minmaxIndex[0], minmaxIndex[1] + 1)
          .forEach((k: any) => {
            tmpData.push(k);
          });
      }

      let sortX = tmpData.slice();
      sortX.sort((a: any, b: any) => a.x - b.x);
      if (sortX.length > 1) {
        minmaxX = [sortX[0].x, sortX[sortX.length - 1].x];
      }

      let sortY = tmpData.slice();
      sortY.sort((a: any, b: any) => a.y - b.y);
      if (sortY.length > 1) {
        minmaxY = [sortY[0].y, sortY[sortY.length - 1].y];
      }

      if (this.isFit) {
        this.minmaxX = minmaxX;
        this.minmaxY = minmaxY;
      } else {
        let offsetX = 10;
        let offsetY = 10;
        if (minmaxX[0] === 100 && minmaxX[1] === 100) {
          offsetX = 10;
        } else if (minmaxX[0] < 100 && minmaxX[1] < 100) {
          offsetX = 100 - minmaxX[0];
        } else if (minmaxX[0] > 100 && minmaxX[1] > 100) {
          offsetX = minmaxX[1] - 100;
        } else {
          offsetX =
            minmaxX[1] - 100 > 100 - minmaxX[0]
              ? minmaxX[1] - 100
              : 100 - minmaxX[0];
        }

        if (minmaxY[0] === 100 && minmaxY[1] === 100) {
          offsetY = 10;
        } else if (minmaxY[0] < 100 && minmaxY[1] < 100) {
          offsetY = 100 - minmaxY[0];
        } else if (minmaxY[0] > 100 && minmaxY[1] > 100) {
          offsetY = minmaxY[1] - 100;
        } else {
          offsetY =
            minmaxY[1] - 100 > 100 - minmaxY[0]
              ? minmaxY[1] - 100
              : 100 - minmaxY[0];
        }
        this.minmaxX = [100 - offsetX, 100 + offsetX];
        this.minmaxY = [100 - offsetY, 100 + offsetY];
      }
    } else {
      this.minmaxX = [90, 110];
      this.minmaxY = [90, 110];
    }
  };

  @action
  private fetchDataMiniChartSuccess = (dataR: any, node: TCTTData) => {
    this.dataMiniChart = {
      code: node.code,
      node: node,
      data: dataR.map((k: any, i: number) => ({
        x: k.date,
        y: k.adClose,
        index: i,
      })),
    };
    this.loading = false;
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    if (dataR.length === 0) {
      this.loading = false;
      return;
    }
    this.data = dataR.map((t: any, _i: number) => {
      return {
        selected: this.keepSelected
          ? this.data.find((d) => d.code === t.code)?.selected
          : _i < 4
          ? true
          : false,
        ...t,
        chart: t.chart.map((k: any, index: number) => ({
          x: parseFloat(k.R),
          y: parseFloat(k.M),
          index: index,
          isFirst: index === 0,
          isEnd: false,
          adClose: k.adClose,
          pctChange: k.pctChange,
          nmVolume: k.nmVolume,
          volMa20: k.volMa20,
          date: k.DT,
        })),
      };
    });
    this.updateMinMax();
    if (this.marketData.length === 0) {
      this.marketData = this.data;
    }
    this.loading = false;
    this.keepSelected = false;
  };

  @action
  private fetchDataVNIndexSuccess = (dataR: any) => {
    this.dataVNIndex = dataR.map((k: any, i: number) => ({
      x: k.date,
      y: k.adClose,
      index: i,
    }));
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  private clearData = () => {
    this.data = [];
    this.savedCode = '';
    this.clearMiniChartData();
  };

  private clearMiniChartData = () => {
    this.savedMiniChart = {
      selected: false,
      name: '',
      code: '',
      type: 0,
      chart: [],
    };

    this.dataMiniChart = {
      code: '',
      node: {},
      data: [],
    };
  };
}

export default new RGGChartStore();
