const Stock360Icon = (props: any) => {
  const { active } = props;
  return (
    <svg
      width={27}
      height={30}
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M25.7004 19H22.6028C22.3584 19 22.1603 19.1981 22.1603 19.4425C22.1603 19.6869 22.3584 19.885 22.6028 19.885H24.632L16.8501 27.667L14.6185 25.4354C14.4503 25.2673 14.1794 25.262 14.0049 25.4236L11.3304 27.9L9.75541 26.3251C9.5826 26.1523 9.30242 26.1523 9.12961 26.3251C8.9568 26.4979 8.9568 26.7781 9.12961 26.9509L11.0057 28.827C11.0919 28.9132 11.2052 28.9566 11.3187 28.9566C11.4264 28.9566 11.5343 28.9174 11.6193 28.8388L14.2938 26.3624L16.5372 28.6057C16.71 28.7785 16.9901 28.7785 17.163 28.6057L25.2579 20.5108V22.5401C25.2579 22.7844 25.456 22.9826 25.7004 22.9826C25.9448 22.9826 26.1429 22.7844 26.1429 22.5401V19.4424C26.1429 19.1981 25.9448 19 25.7004 19Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M9.81635 15.9999H13.1837H16.551C16.799 15.9999 17 15.7821 17 15.5135V9.91889C17 9.3824 16.5972 8.94591 16.102 8.94591H13.6343C13.6384 8.97658 13.6381 9.00812 13.6326 9.03965V7.97298C13.6326 7.43649 13.2298 7 12.7347 7H10.2653C9.77018 7 9.36735 7.43649 9.36735 7.97298V11.6217H6.89796C6.40283 11.6217 6 12.0581 6 12.5946V15.5135C6 15.7822 6.20102 16 6.44895 16L9.81635 15.9999ZM13.6327 9.91889H16.1021V15.027H13.6327V9.91889ZM10.2654 7.97293H12.7347V15.027H10.2654V7.97293ZM6.89802 12.5946H9.3674V15.027H6.89802V12.5946Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M1.2288 23.2792L1.35871 23.3542L1.2288 23.2792C0.333404 24.8302 0.866603 26.8201 2.41755 27.7155C2.92764 28.01 3.48535 28.1501 4.03615 28.15C5.15864 28.1499 6.25283 27.5678 6.85386 26.5268L9.38363 22.1451C10.1223 22.3058 10.8888 22.3908 11.6748 22.3908C17.6138 22.3908 22.4452 17.5593 22.4452 11.6204C22.4452 5.68144 17.6138 0.85 11.6748 0.85C5.73582 0.85 0.904377 5.68144 0.904377 11.6204C0.904377 14.4289 1.98547 16.9894 3.75225 18.9084L1.2288 23.2792ZM11.6748 2.03504C16.9601 2.03504 21.2602 6.33513 21.2602 11.6204C21.2602 16.9057 16.9601 21.2058 11.6748 21.2058C6.38951 21.2058 2.08936 16.9057 2.08936 11.6204C2.08942 6.33513 6.38951 2.03504 11.6748 2.03504ZM5.82761 25.9343L5.95751 26.0093L5.82761 25.9343C5.25905 26.9191 3.99493 27.2579 3.01004 26.6893C2.02525 26.1207 1.6865 24.8565 2.2551 23.8717L4.62922 19.7596C5.66701 20.6592 6.878 21.3639 8.20495 21.8166L5.82761 25.9343Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
      </g>
    </svg>
  );
};

export default Stock360Icon;
