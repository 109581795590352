import { observable, action, makeAutoObservable } from 'mobx';
import { ISODateFormat } from '../utils/DateFormat';
import { Moment } from 'moment';
import api from './request';

export const TimePeroidTypes = ['1D', '1W', '1M', '3M'];

export const StockTypes = ['KLGD', 'GTGD'];

export interface KhoiNgoaiTPChartProps {
  data: {
    top: Array<KhoiNgoaiData>;
    low: Array<KhoiNgoaiData>;
  };
  dataTable: Array<KhoiNgoaiDataTable>;
  loadData: (isLoading?: boolean) => void;
  loading: boolean;
  statusTime?: string;
  setOpenZoom: (open: boolean) => void;
  openZoom: boolean;
  filter: IFilter;
  updateFiltes: (filter: IFilter) => void;
}

export interface IFilter {
  hnx: boolean;
  upcom: boolean;
  hose: boolean;
  time: '1D' | '1W' | '1M' | '3M';
  from?: Moment;
  to?: Moment;
}

export interface KhoiNgoaiData {
  code: string;
  tradingDate: string;
  netVal: number;
  netVol: number;
}

export interface KhoiNgoaiDataTable {
  code: string;
  code1: string;
  tradingDate: string;
  tradingDate1: string;
  netVal: number;
  netVal1: number;
  netVol: number;
  netVol1: number;
}

class KhoiNgoaiTPChartStore implements KhoiNgoaiTPChartProps {
  private apiPath: string = 'api/foreigns/top';

  @observable
  data: {
    top: Array<KhoiNgoaiData>;
    low: Array<KhoiNgoaiData>;
  } = {
    top: [],
    low: [],
  };

  @observable
  filter: IFilter = {
    hnx: true,
    hose: true,
    upcom: true,
    time: '1D',
  };

  @observable
  dataTable: Array<KhoiNgoaiDataTable> = [];

  @observable
  openZoom: boolean = false;

  @observable
  loading: boolean = false;

  @observable
  statusTime?: string = '';

  @action
  public updateFiltes = async (filter: IFilter) => {
    this.filter = filter;
    this.loadData(true);
  };

  @action
  public setOpenZoom = async (open: boolean) => {
    this.openZoom = open;
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.loadData();
  };

  @action
  public loadData = (press?: boolean, isLoading: boolean = true) => {
    this.loading = isLoading;
    let fl = [];
    let time: string = this.filter.time;
    if (this.filter.hnx) fl.push('hnx');
    if (this.filter.hose) fl.push('hose');
    if (this.filter.upcom) fl.push('upcom');
    const floorString = fl.length > 0 ? fl.join(',') : 'hnx,hose,upcom';
    if (fl.length === 0) {
      this.filter = { ...this.filter, hnx: true, hose: true, upcom: true };
    }
    const pressSearch = press ? 1 : 0;
    let queryString: string = `${this.apiPath}?floor=${floorString}&time=${time}&press=${pressSearch}`;
    if (this.filter.from || this.filter.to) {
      time = '';
      queryString = `${
        this.apiPath
      }?floor=${floorString}&time=${time}&from=${this.filter.from
        ?.format(ISODateFormat)
        .toString()}&to=${this.filter.to
        ?.format(ISODateFormat)
        .toString()}&press=${pressSearch}`;
    }
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: {
    top: Array<KhoiNgoaiData>;
    low: Array<KhoiNgoaiData>;
  }) => {
    this.data = data;
    this.dataTable = data.top.map((t, index) => ({
      ...t,
      code1: data.low[index].code,
      tradingDate1: data.low[index].tradingDate,
      netVal1: data.low[index].netVal,
      netVol1: data.low[index].netVol,
    }));
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    this.loading = false;
  };
}

export default new KhoiNgoaiTPChartStore();
