import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export const AppBlockTitle: React.FC<{
  title: string;
  isShowInfo?: boolean;
  fontSize?: string;
  height?: string;
}> = ({ title, isShowInfo: isShowInfo, fontSize, height }) => (
  <Box paddingLeft={1} sx={{ display: 'flex', height: height }}>
    <Typography
      variant="h6"
      fontSize={fontSize ? fontSize : 15}
      fontWeight={'bold'}
    >
      {title}
    </Typography>
    {isShowInfo && (
      <Tooltip
        title="Danh sách CP mạnh được admin đề xuất ở từng giai đoạn thị trường"
        placement="bottom-end"
      >
        <InfoIcon sx={{ fontSize: '10px' }} />
      </Tooltip>
    )}
  </Box>
);
