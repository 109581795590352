import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const AppZoomDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  handleDownloadImageZoom?: () => void;
  title?: string;
  maxWidth?: any;
  fullScreen?: boolean;
  children?: React.ReactNode;
}> = ({
  open,
  setOpen,
  title,
  children,
  maxWidth,
  onClose,
  fullScreen,
  handleDownloadImageZoom,
}) => {
  // const handleClickOpen = () => {
  //     setOpen(true);
  // };
  const openModal = React.useMemo(() => open, [open]);
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };
  return (
    <Dialog
      keepMounted={true}
      disableEscapeKeyDown
      open={openModal}
      fullScreen={fullScreen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={maxWidth ?? 'xl'}
    >
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <AppBar position="static" color="secondary">
          <DialogTitle
            style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="subtitle2"
                sx={{ marginRight: 2, fontWeight: 'bold', fontSize: 15 }}
              >
                {title}
              </Typography>
              {handleDownloadImageZoom && (
                <IconButton
                  aria-label="zoom"
                  onClick={handleDownloadImageZoom}
                  sx={{
                    position: 'absolute',
                    right: 50,
                    top: title ? 12 : 0,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <FileDownloadIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: title ? 8 : 0,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
        </AppBar>
        <DialogContent>{children}</DialogContent>
      </Box>
    </Dialog>
  );
};
