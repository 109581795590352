import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppRoutes from './routes';
import ColorModeContext from './store/ColorModeContext';
import { ToastContainer } from 'react-toastify';
import { setConfiguration } from 'react-grid-system';
import 'react-toastify/dist/ReactToastify.css';

(async () => {
  if ('ResizeObserver' in window === false) {
    const module = await import('@juggle/resize-observer');
    // @ts-ignore
    window.ResizeObserver = module.ResizeObserver;
  }
})();

setConfiguration({
  defaultScreenClass: 'xl',
  gridColumns: 24,
  gutterWidth: 7.5,
  breakpoints: [678, 1024, 1280, 1440, 1600, 1920],
});

const getDesignTokens = (mode: PaletteMode) => ({
  breakpoints: {
    values: {
      xs: 1,
      sm: 678,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          background: {
            paper: '#fff',
          },
        }
      : {
          // palette values for dark mode
          background: {
            default: '#000000',
            paper: '#1A2132',
          },
          primary: {
            main: '#F2BB0E',
            light: '#e3f2d',
            dark: '#ffffff33',
          },
          secondary: {
            main: '#FFFFFF',
            light: '#FFFFFF33',
            dark: '#1A2132',
          },
          error: {
            main: '#FF0000',
            light: '#fcdede',
          },
          success: {
            main: '#00FF00',
            light: '#0DAE1D',
          },
          warning: {
            main: '#FFD900',
            light: '#AB8613',
          },
        }),
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: mode === 'light' ? '#fff' : '#1A2132',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' ? '#fff' : '#1A2132',
          backgroundImage: 'unset',
        },
      },
    },
  },
});

const App: React.FC = () => {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
