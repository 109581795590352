import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';
import { VNIndexData } from '../RRGChartStore';

export const TimePeroidTypes = ['Ngày', 'Tuần'];

export interface RRGStoreProps {
  dataRRG: Array<RRGData>;
  loadData: () => void;
  loadDataVNIndex: () => void;
  loadDataCodes: () => void;
  loading: boolean;
  dataVNIndex: Array<VNIndexData>;
  isFit: boolean;
  minmaxIndex: number[];
  minmaxBKIndex: number[];
  minmaxX: number[];
  minmaxY: number[];
  updateMinMaxIndex: (minmaxIndex: number[], isPlay?: boolean) => void;
  timePeroid: string;
  setTimePeroid: (timePeroid: string) => void;
  setCode: (code: string, loadData?: boolean) => void;
  code: string;
  codes: Array<Array<string>>;
  codesSelected: Array<string>;
  setCodesSelected: (codesSelected: Array<string>) => void;
}

export interface RRGData {
  selected: boolean;
  code: string;
  name: string;
  type: number;
  chart: {
    index: number;
    y: number;
    x: number;
    isFirst: boolean;
    DT: string;
    adChange: number;
    adClose: number;
    nmVolume: number;
    pctChange: number;
    volMa20: number;
  }[];
}

class RRGStore implements RRGStoreProps {
  private apiPath: string = 'api/rrg';
  private apiPathVnindex: string = 'api/rrg/vnindex';
  private apiPathCode: string = 'api/company/compare';

  @observable
  dataRRG: Array<RRGData> = [];

  @observable
  code: string = 'MBS';

  @observable
  codesSelected: Array<string> = [];

  @observable
  codes: Array<Array<string>> = [];

  @observable
  loading: boolean = true;

  @observable
  minmaxX: number[] = [90, 110];

  @observable
  minmaxY: number[] = [90, 110];

  @observable
  minmaxIndex: number[] = [41, 49];

  @observable
  minmaxBKIndex: number[] = [41, 49];

  @observable
  dataVNIndex: Array<VNIndexData> = [];

  @observable
  timePeroid: string = 'Ngày';

  @observable
  isFit: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setCode = async (code: string, loadData: boolean = true) => {
    this.code = code;
    if (loadData) {
      this.loadDataCodes();
      // this.loadData();
    }
  };

  @action
  setCodesSelected = (codesSelected: Array<string>) => {
    this.codesSelected = codesSelected;
    this.loadData();
  };

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.timePeroid = timePeroid;
    this.loadData();
    this.loadDataVNIndex();
  };

  @action
  public updateSelected = (lineID: string, selected: boolean) => {
    let selectedItem = this.dataRRG.find((d: any) => d.code === lineID);
    if (selectedItem) selectedItem.selected = selected;
    this.updateMinMax();
  };

  @action
  public setFit = (isFit: boolean) => {
    this.isFit = isFit;
    this.updateMinMax();
  };

  @action
  public loadDataCodes = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPathCode}?code=${this.code}&list=1`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataCodesSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadDataVNIndex = async () => {
    const week = this.timePeroid === 'Tuần' ? 1 : 0;
    const queryString: string = `${this.apiPathVnindex}?week=${week}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataVNIndexSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private updateMinMax = () => {
    const dataChart = this.dataRRG.slice().filter((t) => t.selected);
    if (dataChart.length > 0) {
      let minmaxX = [0, 1];
      let minmaxY = [0, 1];
      let tmpData: any = [];
      const minmaxIndex = this.minmaxIndex.slice();
      for (let index = 0; index < dataChart.length; index++) {
        const element = dataChart[index];
        element.chart
          .slice(minmaxIndex[0], minmaxIndex[1] + 1)
          .forEach((k: any) => {
            tmpData.push(k);
          });
      }

      let sortX = tmpData.slice();
      sortX.sort((a: any, b: any) => a.x - b.x);
      if (sortX.length > 1) {
        minmaxX = [sortX[0].x, sortX[sortX.length - 1].x];
      }

      let sortY = tmpData.slice();
      sortY.sort((a: any, b: any) => a.y - b.y);
      if (sortY.length > 1) {
        minmaxY = [sortY[0].y, sortY[sortY.length - 1].y];
      }

      if (this.isFit) {
        this.minmaxX = minmaxX;
        this.minmaxY = minmaxY;
      } else {
        let offsetX = 10;
        let offsetY = 10;
        if (minmaxX[0] === 100 && minmaxX[1] === 100) {
          offsetX = 10;
        } else if (minmaxX[0] < 100 && minmaxX[1] < 100) {
          offsetX = 100 - minmaxX[0];
        } else if (minmaxX[0] > 100 && minmaxX[1] > 100) {
          offsetX = minmaxX[1] - 100;
        } else {
          offsetX =
            minmaxX[1] - 100 > 100 - minmaxX[0]
              ? minmaxX[1] - 100
              : 100 - minmaxX[0];
        }

        if (minmaxY[0] === 100 && minmaxY[1] === 100) {
          offsetY = 10;
        } else if (minmaxY[0] < 100 && minmaxY[1] < 100) {
          offsetY = 100 - minmaxY[0];
        } else if (minmaxY[0] > 100 && minmaxY[1] > 100) {
          offsetY = minmaxY[1] - 100;
        } else {
          offsetY =
            minmaxY[1] - 100 > 100 - minmaxY[0]
              ? minmaxY[1] - 100
              : 100 - minmaxY[0];
        }
        this.minmaxX = [100 - offsetX, 100 + offsetX];
        this.minmaxY = [100 - offsetY, 100 + offsetY];
      }
    } else {
      this.minmaxX = [90, 110];
      this.minmaxY = [90, 110];
    }
  };

  // @action
  // public loadDataByStockCode = async (code: string) => {
  //   if (code === '') {
  //     this.clearData();
  //     return;
  //   }
  //   this.savedCode = code;
  //   this.loading = true;
  //   const week = this.timePeroid === 'Tuần' ? 1 : 0;
  //   const queryString: string = `${this.apiPath}?week=${week}&codes=${code}`;
  //   await api
  //     .get(queryString)
  //     .then((resp) => resp.data)
  //     .then(this.fetchDataSuccess)
  //     .catch(this.fetchDataError);
  // };

  private clearData = () => {
    this.dataRRG = [];
  };

  @action
  public updateMinMaxIndex = (minmaxIndex: number[], isPlay?: boolean) => {
    this.minmaxIndex = minmaxIndex;
    if (!isPlay) {
      this.minmaxBKIndex = minmaxIndex;
    }
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const week = this.timePeroid === 'Tuần' ? 1 : 0;
    let codes: any = [];
    this.codesSelected.forEach((k) => {
      this.codes.forEach((c) => {
        if (k === c[1]) {
          codes.push(c[0]);
        }
      });
    });
    const queryString: string = `${this.apiPath}?week=${week}&codes=${
      codes.length > 0 ? codes.join(',') : ''
    }`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    if (dataR.length === 0) {
      this.loading = false;
      return;
    }
    this.dataRRG = dataR.map((t: any, _i: number) => {
      return {
        selected: true,
        ...t,
        chart: t.chart.map((k: any, index: number) => ({
          x: parseFloat(k.R),
          y: parseFloat(k.M),
          index: index,
          isFirst: index === 0,
          isEnd: false,
          adClose: k.adClose,
          pctChange: k.pctChange,
          nmVolume: k.nmVolume,
          volMa20: k.volMa20,
          date: k.DT,
        })),
      };
    });
    this.updateMinMax();
    this.loading = false;
  };

  @action
  private fetchDataCodesSuccess = (dataR: any) => {
    this.codes = dataR;
    const codes = dataR.map((k: string) => k[1]);
    this.codesSelected = [codes[0], this.code];
    this.loadData();
  };

  @action
  private fetchDataVNIndexSuccess = (dataR: any) => {
    this.dataVNIndex = dataR.map((k: any, i: number) => ({
      x: k.date,
      y: k.adClose,
      index: i,
    }));
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default RRGStore;
