import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const TabBlock = styled(Tab)(({ theme }) => ({
  background: '#2A334C',
  width: '200px',
  borderRight: '1px solid #fff',
  minHeight: '38px',
  height: '38px',
  paddingTop: '10px',
  '&.Mui-selected': {
    background: '#F2BB0E',
    color: '#000',
  },
  textTransform: 'none',
}));

export const AppTaiChinhTabs: React.FC<any> = (props) => {
  const styleTabI = props.styleTabIndicator || { display: 'none' };
  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        style: styleTabI,
      }}
      sx={{
        minHeight: 34,
      }}
    >
      {props.children}
    </Tabs>
  );
};

const AppTabs: React.FC<any> = (props) => {
  const styleTabI = props.styleTabIndicator || { display: 'none' };
  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        style: styleTabI,
      }}
      sx={{
        minHeight: 37,
        '& .MuiTabs-flexContainer': {
          flexWrap: 'wrap',
        },
      }}
    >
      {props.children}
    </Tabs>
  );
};

export const AppTabHeader: React.FC<any> = (props) => (
  <Tab
    {...props}
    sx={{
      textTransform: 'unset',
      fontSize: '13px',
      fontWeight: 700,
      minHeight: 37,
      height: 37,
      color: 'rgba(255,255,255,.7)',
    }}
  />
);

export default AppTabs;
