import { observable, action, makeAutoObservable } from 'mobx';
import api from '../../request';

export const TimePeroidTypes = ['Cũ tới mới', 'Mới tới cũ'];
export const ReportingPeriod = [
  'Cả năm',
  'Quý 1',
  'Quý 2',
  'Quý 3',
  'Quý 4',
  'Cùng quý 1',
  'Cùng quý 2',
  'Cùng quý 3',
  'Cùng quý 4',
];
export const ReportingPeriodCompare = [
  'Cả năm',
  'Cùng quý 1',
  'Cùng quý 2',
  'Cùng quý 3',
  'Cùng quý 4',
];
export const DisplayPeriods = [
  'Tất cả',
  '1 kỳ',
  '2 kỳ',
  '3 kỳ',
  '4 kỳ',
  '5 kỳ',
  '6 kỳ',
  '7 kỳ',
  '8 kỳ',
];
export const CurrencyPeroidTypes = ['Triệu đồng', 'Tỷ đồng'];

export interface FinancialIndicatorsStoreProps {
  data: {
    code: string;
    name: string;
    label: string[];
    data: Array<FinancialIndicatorsData>;
  };
  loadData: () => void;
  loading: boolean;
  ifFilter: FIFilter;
  setFIFilter: (ifFilter: string, value: number) => void;
  code: string;
  setCode: (code: string, loadData?: boolean) => void;
  type: number;
  setType: (type: number) => void;
  setCodeName: (codeName: string[]) => void;
  compareCodeData: string[];
  selectedCompareCodeData: string[];
  setCompareCode: (code: string, loadData?: boolean) => void;
  loadCompareCodeData: () => void;
  ReportingYearPeriod: string[];
}

export interface CompareCode {
  code: string;
  name: string;
}

export interface FinancialIndicatorsData {
  itemName: string;
  itemCode: string;
  unit: string;
  displayLevel: number;
  tooltip: string;
  data: Array<{
    code: string;
    itemCode: number;
    reportDate: string;
    value: number;
  }>;
}

export interface FIFilter {
  timePeroidTypes: number;
  reportingPeriod: number;
  reportingYearPeriod: number;
  displayPeriods: number;
  currencyPeroidTypes: number;
}

class FinancialIndicatorsStore implements FinancialIndicatorsStoreProps {
  private apiPath: string = 'api/company/ratios';
  private apiPathCompare: string = 'api/company/compare';

  @observable
  data: {
    code: string;
    name: string;
    label: string[];
    tooltip: string;
    data: Array<FinancialIndicatorsData>;
  } = {
    code: '',
    name: '',
    data: [],
    label: [],
    tooltip: '',
  };

  @observable
  loading: boolean = true;

  @observable
  code: string = 'MBS';

  @observable
  ReportingYearPeriod: string[] = [];

  @observable
  ifFilter: FIFilter = {
    timePeroidTypes: 0,
    reportingPeriod: 1,
    reportingYearPeriod: 0,
    displayPeriods: 5,
    currencyPeroidTypes: 0,
  };

  constructor() {
    makeAutoObservable(this);
    if (this.type === 1) {
      //Fill Year
      let currentYear = new Date().getFullYear();
      while (currentYear >= 2011) {
        this.ReportingYearPeriod.push(currentYear.toString());
        currentYear = currentYear - 1;
      }

      let currentQ = Math.floor((new Date().getMonth() + 3) / 3);
      currentYear = new Date().getFullYear();

      if (currentQ === 1) {
        currentQ = 4;
        currentYear = currentYear - 1;
      } else currentQ = currentQ - 1;

      this.ifFilter.reportingPeriod = currentQ;
      this.ifFilter.reportingYearPeriod = this.ReportingYearPeriod.indexOf(
        currentYear.toString(),
      );
    }
  }

  @observable
  compareCodeData: string[] = [];

  @observable
  selectedCompareCodeData: string[] = [];

  @action
  public setCompareCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadCompareCodeData();
  };

  @action
  public loadCompareCodeData = async () => {
    this.loading = true;
    let queryString = `${this.apiPathCompare}?code=${this.code}&list=1`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataCodeSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataCodeSuccess = (dataR: any) => {
    dataR = dataR.slice(1, dataR.length);
    this.compareCodeData = dataR.map((a: any) => a[0]);
    if (dataR && dataR.length > 0) {
      this.selectedCompareCodeData = dataR.slice(0, 5).map((a: any) => a[0]);
    }
    this.loading = false;
    this.loadData();
  };

  @action
  public setCodeName = (codeName: string[]) => {
    this.selectedCompareCodeData = codeName;
    this.loadData();
  };

  @observable
  type: number = 1;

  @action
  public setType = (type: number) => {
    this.type = type;
  };

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    let queryString: string = `${this.apiPath}?code=${this.code}&period=${
      this.ifFilter.reportingPeriod
    }&year=${
      this.ReportingYearPeriod[this.ifFilter.reportingYearPeriod]
    }&period_num=${this.ifFilter.displayPeriods}`;
    if (this.type === 2)
      queryString = `${this.apiPathCompare}?code=${this.code}&period=${
        this.ifFilter.reportingPeriod
      }&year=${
        this.ReportingYearPeriod[this.ifFilter.reportingYearPeriod]
      }&list=${this.selectedCompareCodeData.join(',')}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public setFIFilter = (ifFilter: string, value: number) => {
    if (ifFilter === 'currencyPeroidTypes')
      this.ifFilter.currencyPeroidTypes = value;
    else if (ifFilter === 'displayPeriods')
      this.ifFilter.displayPeriods = value;
    else if (ifFilter === 'reportingPeriod')
      this.ifFilter.reportingPeriod = value;
    else if (ifFilter === 'reportingYearPeriod')
      this.ifFilter.reportingYearPeriod = value;
    else if (ifFilter === 'timePeroidTypes') {
      this.ifFilter.timePeroidTypes = value;
    }

    this.loadData();
  };
}

export default FinancialIndicatorsStore;
