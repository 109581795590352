import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { routes } from '../const/config';
import AppLayout from '../components/layout/ApppLayout';
import { Helmet } from 'react-helmet';
import { useStores } from '../store/useStores';

const DynamicLoader = React.memo(function (props: { component: string }) {
  const LazyComponent = React.lazy(() => import(`./${props.component}`));
  return <Suspense>{LazyComponent && <LazyComponent />}</Suspense>;
});

export const Dashboard = () => {
  const { authStore } = useStores();

  const params = useParams();
  const item = React.useMemo(
    () => routes.find((i: any) => i.slug === params.id),
    [routes, params],
  );
  React.useEffect(() => {
    authStore.loadUserTier();
  });
  const appTitle = React.useMemo(() => {
    let strTitle = 'AlphaStock - Công cụ hỗ trợ đầu tư chứng khoán toàn diện';
    if (params.id === 'trading-signal')
      strTitle = 'AlphaStock - Tín hiệu giao dịch';
    if (params.id === 'idea-trading') strTitle = 'AlphaStock - Ý tưởng đầu tư';
    if (params.id === 'relative-rotation-graphs')
      strTitle = 'AlphaStock - Hệ thống RRG';
    if (params.id === 'stock360') strTitle = 'AlphaStock - Cổ phiếu 360';
    if (params.id === 'stock360') strTitle = 'AlphaStock - Cổ phiếu 360';
    if (params.id === 'stock360') strTitle = 'AlphaStock - Cổ phiếu 360';
    if (params.id === 'industry-developments')
      strTitle = 'AlphaStock - Diễn biến ngành';
    if (params.id === 'industry-developments-stock')
      strTitle = 'AlphaStock - Diễn biến ngành CP';
    if (params.id === 'industry-volatility')
      strTitle = 'AlphaStock - Biến động ngành';
    if (params.id === 'industry-volatility-stock')
      strTitle = 'AlphaStock - Biến động ngành CP';
    if (params.id === 'reporting-analysis')
      strTitle = 'AlphaStock - Báo cáo phân tích';
    if (params.id === 'investment-strategy')
      strTitle = 'AlphaStock - Chiến lược giao dịch';
    if (params.id === 'top-stock') strTitle = 'AlphaStock - TOP Cổ phiếu';
    return strTitle;
  }, [params.id]);
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content={
            params.id === 'upgrade'
              ? 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no'
              : 'width=device-width,initial-scale=0.5,maximum-scale=0.5,minimum-scale=0.5,user-scalable=no'
          }
        />
        <title>{appTitle}</title>
      </Helmet>
      <AppLayout>
        <DynamicLoader component={item ? item.module : 'HomePage'} />
      </AppLayout>
    </>
  );
};

export default Dashboard;
