const AppUserIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.18888 11.9016C6.84132 11.5141 8.6676 11.5474 11.3235 11.9151C11.5159 11.943 11.6916 12.0396 11.8183 12.1869C11.945 12.3343 12.0142 12.5225 12.0129 12.7169C12.0129 12.9029 11.949 13.0835 11.8335 13.2245C11.6322 13.4704 11.426 13.7123 11.215 13.9499H12.2384C12.3027 13.8732 12.3675 13.7949 12.4329 13.7155C12.6628 13.4336 12.7882 13.081 12.7879 12.7173C12.7879 11.9326 12.2152 11.2564 11.4297 11.1479C8.711 10.7716 6.8107 10.7356 4.07689 11.1351C3.28329 11.251 2.71289 11.9376 2.71289 12.7277C2.71289 13.0784 2.8272 13.4252 3.04382 13.7062C3.10775 13.7891 3.17092 13.8705 3.23369 13.9503H4.23228C4.03598 13.7153 3.84451 13.4763 3.658 13.2334C3.54716 13.088 3.48737 12.9102 3.48789 12.7273C3.48789 12.31 3.78782 11.9601 4.18888 11.9016ZM7.75039 8.1374C8.05571 8.1374 8.35805 8.07726 8.64013 7.96042C8.92221 7.84358 9.17852 7.67232 9.39441 7.45643C9.61031 7.24053 9.78157 6.98422 9.89841 6.70214C10.0153 6.42006 10.0754 6.11773 10.0754 5.8124C10.0754 5.50708 10.0153 5.20475 9.89841 4.92266C9.78157 4.64058 9.61031 4.38428 9.39441 4.16838C9.17852 3.95248 8.92221 3.78122 8.64013 3.66438C8.35805 3.54754 8.05571 3.4874 7.75039 3.4874C7.13376 3.4874 6.54239 3.73236 6.10637 4.16838C5.67034 4.6044 5.42539 5.19577 5.42539 5.8124C5.42539 6.42903 5.67034 7.0204 6.10637 7.45643C6.54239 7.89245 7.13376 8.1374 7.75039 8.1374ZM7.75039 8.9124C8.57256 8.9124 9.36106 8.5858 9.94242 8.00443C10.5238 7.42307 10.8504 6.63457 10.8504 5.8124C10.8504 4.99023 10.5238 4.20173 9.94242 3.62037C9.36106 3.03901 8.57256 2.7124 7.75039 2.7124C6.92822 2.7124 6.13972 3.03901 5.55836 3.62037C4.977 4.20173 4.65039 4.99023 4.65039 5.8124C4.65039 6.63457 4.977 7.42307 5.55836 8.00443C6.13972 8.5858 6.92822 8.9124 7.75039 8.9124Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 14.725C11.6021 14.725 14.725 11.6021 14.725 7.75C14.725 3.89786 11.6021 0.775 7.75 0.775C3.89786 0.775 0.775 3.89786 0.775 7.75C0.775 11.6021 3.89786 14.725 7.75 14.725ZM7.75 15.5C12.0303 15.5 15.5 12.0303 15.5 7.75C15.5 3.46968 12.0303 0 7.75 0C3.46968 0 0 3.46968 0 7.75C0 12.0303 3.46968 15.5 7.75 15.5Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};
export default AppUserIcon;
