import axios from 'axios';
import { AppBaseUrl as baseURL } from '../const/config';

function buildApi() {
  const instance = axios.create({
    baseURL,
    withCredentials: false,
  });

  instance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
    },
    withCredentials: false,
  }));

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && 401 === error.response.status) {
        let savedToken = window.localStorage.getItem('access_token');
        if (savedToken !== null) {
          localStorage.removeItem('userData');
          localStorage.removeItem('access_token');
          window.location.reload();
        }
      }
      return Promise.reject(error);
    },
  );

  return instance;
}

const api = buildApi();
export default api;
